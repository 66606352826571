import en from '@/locales/en-US.json';
import ja from '@/locales/ja-JP.json';
import { store } from '@/store/index';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { readUserLocale } from '@/store/main/getters';

Vue.use(VueI18n);

const messages = {
  'en-US': en,
  'ja-JP': ja,
  // Fallback, in case only the language code is passed
  // to avoid warnings (please always use the full locale).
  en,
  ja,
};

export const i18n = new VueI18n({
  locale: readUserLocale(store),
  dateTimeFormats: {
    'en-US': {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      },
      long: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        weekday: 'short',
        hour: 'numeric',
        minute: 'numeric',
      },
    },
    'ja-JP': {
      short: {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      },
      long: {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        weekday: 'short',
        hour: '2-digit',
        minute: '2-digit',
      },
    },
  },
  numberFormats: {
    'en-US': {
      currency: {
        style: 'currency',
        currency: 'USD',
      },
    },
    'ja-JP': {
      currency: {
        style: 'currency',
        currency: 'JPY',
      },
    },
  },
  messages,
});
