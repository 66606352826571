import Vue from 'vue';
import Router, { Route } from 'vue-router';

import { store } from '@/store/index';
import { dispatchCheckLoggedIn } from '@/store/main/actionsMain';
import { readIsLoggedIn } from '@/store/main/getters';
import { usePermissionComposable } from '@/composables/usePermission';

Vue.use(Router);

const isLogin = async (): Promise<boolean> => {
  await dispatchCheckLoggedIn(store);

  return !!readIsLoggedIn(store);
};

function getEnvironmentBasedRedirectUrl(basePath) {
  const baseUrl =
    {
      production: 'https://chemican.com',
      staging: 'https://stg.chemican.com',
    }[process.env.VUE_APP_ENV] || '';

  return baseUrl + basePath;
}

export default new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('./views/layouts/lpLayout/LPLayout.vue'),
      beforeEnter: (to, from, next) => {
        if (to.path === '/') {
          const redirectUrl = getEnvironmentBasedRedirectUrl('/');
          if (
            redirectUrl &&
            process.env.VUE_APP_ENV !== 'development' &&
            process.env.NODE_ENV !== 'development'
          ) {
            window.location.href = redirectUrl;
          } else {
            next();
          }
        } else {
          next();
        }
      },
      children: [
        {
          path: '',
          name: 'top',
          beforeEnter: (to, from, next) => {
            const redirectUrl = getEnvironmentBasedRedirectUrl('/');
            if (
              redirectUrl &&
              process.env.VUE_APP_ENV !== 'development' &&
              process.env.NODE_ENV !== 'development'
            ) {
              window.location.href = redirectUrl;
            } else {
              next();
            }
          },
        },
        {
          path: 'corporate',
          name: 'corporate',
          beforeEnter: (to, from, next) => {
            const redirectUrl = getEnvironmentBasedRedirectUrl('/corporate');
            if (
              redirectUrl &&
              process.env.VUE_APP_ENV !== 'development' &&
              process.env.NODE_ENV !== 'development'
            ) {
              window.location.href = redirectUrl;
            } else {
              next();
            }
          },
        },
        {
          path: 'privacypolicy',
          name: 'privacypolicy',
          component: () =>
            import(
              /* webpackChunkName: "user" */ './views/pages/privacyPolicy/PrivacyPolicyPage.vue'
            ),
        },
        {
          path: 'terms',
          name: 'terms',
          component: () =>
            import(
              /* webpackChunkName: "user" */ './views/pages/termsOfUse/TermsOfUsePage.vue'
            ),
        },
        {
          path: 'login',
          name: 'login',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () =>
            import(
              /* webpackChunkName: "login" */ './views/pages/login/LoginPage.vue'
            ),
          props: (route) => ({
            messageText: route.params.messageText,
            messageKey: route.params.messageKey,
            messageType: route.params.messageType,
          }),
        },
        {
          path: '/login/verify/:mode(totp|sms)',
          name: 'loginVerify',
          component: () =>
            import(
              /* webpackChunkName: "two-factor-verify" */ './views/main/general/LoginVerifyPageComponent.vue'
            ),
          props: (route) => ({
            mode: route.params.mode,
            tfaPhoneNumber: route.params.tfaPhoneNumber,
            tfaToken: route.params.tfaToken,
          }),
        },
        {
          path: 'signup/activation',
          name: 'activation',
          component: () =>
            import(
              /* webpackChunkName: "userActivation" */ './views/pages/signUpActivation/UserActivationPage.vue'
            ),
        },
        {
          path: 'signup/complete',
          name: 'signUpComplete',
          component: () =>
            import(
              /* webpackChunkName: "signupComplete" */ './views/pages/signUpComplete/SignUpCompletePage.vue'
            ),
        },
        {
          path: 'signup',
          name: 'signUp',
          component: () =>
            import(
              /* webpackChunkName: "login" */ './views/pages/signUp/SignUpPage.vue'
            ),
        },
        {
          path: 'inquiry',
          name: 'inquiry',
          component: () =>
            import(
              /* webpackChunkName: "login" */ './views/pages/inquiry/InquiryPage.vue'
            ),
        },
        {
          path: 'en/contact',
          name: 'contacten',
          component: () =>
            import(
              /* webpackChunkName: "login" */ './views/pages/enContact/EnContactPage.vue'
            ),
        },
        {
          path: 'reset-password',
          name: 'resetPassword',
          component: () =>
            import(
              /* webpackChunkName: "login" */ './views/pages/resetPassword/ResetPasswordPage.vue'
            ),
        },
        {
          path: 'password-recovery',
          name: 'passwordRecovery',
          component: () =>
            import(
              /* webpackChunkName: "login" */ './views/pages/passwordRecovery/PasswordRecoveryPage.vue'
            ),
        },
        {
          path: 'unregister',
          name: 'unregister',
          component: () =>
            import(
              /* webpackChunkName: "user" */ './views/pages/unregister/UnregisterPage.vue'
            ),
        },
      ],
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () =>
        import('./views/layouts/dashboardLayout/DashboardLayout.vue'),
      beforeEnter: async (to: Route, from: Route, next) => {
        const isLoggedIn = await isLogin();
        if (isLoggedIn) {
          next();
        } else {
          next({ name: 'login', query: { redirect: to.fullPath } });
        }
      },
      children: [
        {
          path: 'sds/upload',
          name: 'sdsUpload',
          component: () =>
            import(
              /* webpackChunkName: "sds-upload" */ './views/pages/sdsUpload/SdsUploadPage.vue'
            ),
          beforeEnter: async (to: Route, from: Route, next) => {
            const {
              hasPermission,
              PERMISSIONS: { SDS_UPLOAD },
            } = usePermissionComposable();
            if (!hasPermission(SDS_UPLOAD)) {
              return next({ name: 'sdsList' });
            } else {
              return next();
            }
          },
        },
        {
          path: 'sds/index',
          name: 'sdsList',
          component: () =>
            import(
              /* webpackChunkName: "sds-list" */ './views/pages/sdsIndex/SdsIndexPage.vue'
            ),
        },
        {
          path: 'dynamic/list',
          name: 'sdsDynamicList',
          component: () =>
            import(
              /* webpackChunkName: "processing-sds" */ './views/pages/dynamicList/DynamicListPage.vue'
            ),
          beforeEnter: async (to: Route, from: Route, next) => {
            const {
              hasPermission,
              PERMISSIONS: { CHECK_LIST_READ },
            } = usePermissionComposable();
            if (!hasPermission(CHECK_LIST_READ)) {
              return next({ name: 'sdsList' });
            } else {
              return next();
            }
          },
        },
        {
          path: 'dynamic/list/:id',
          name: 'sdsDynamicListResult',
          component: () =>
            import(
              /* webpackChunkName: "processing-sds" */ './views/pages/dynamicListDetail/DynamicListResultPage.vue'
            ),
          beforeEnter: async (to: Route, from: Route, next) => {
            const {
              hasPermission,
              PERMISSIONS: { CHECK_LIST_READ },
            } = usePermissionComposable();
            if (!hasPermission(CHECK_LIST_READ)) {
              return next({ name: 'sdsList' });
            } else {
              return next();
            }
          },
        },
        {
          path: 'sds/index/error',
          name: 'sdsError',
          component: () =>
            import(
              /* webpackChunkName: "error-sds" */ './views/pages/sdsIndexError/SdsIndexErrorPage.vue'
            ),
        },
        {
          path: 'company/departments',
          name: 'companyDepartments',
          component: () =>
            import(
              /* webpackChunkName: "company-departments" */ './views/pages/companyDepartments/CompanyDepartmentsPage.vue'
            ),
        },
        {
          path: 'company/users',
          name: 'companyUsers',
          component: () =>
            import(
              /* webpackChunkName: "company-users" */ './views/pages/companyUsers/CompanyUsersPage.vue'
            ),
        },
        {
          path: 'company/tags',
          name: 'companyTags',
          component: () =>
            import(
              /* webpackChunkName: "company-tags" */ './views/pages/companyTags/CompanyTagsPage.vue'
            ),
        },
        {
          path: 'company/whitelist_ips',
          name: 'companyWhitelistIps',
          component: () =>
            import(
              /* webpackChunkName: "company-whitelist-ips" */ './views/pages/companyWhiteListIps/CompanyWhitelistIpsPage.vue'
            ),
        },
        {
          path: 'company',
          name: 'company',
          component: () =>
            import(
              /* webpackChunkName: "company" */ './views/pages/company/CompanyPage.vue'
            ),
        },
        {
          path: 'user',
          name: 'user',
          component: () =>
            import(
              /* webpackChunkName: "user" */ './views/pages/user/UserPage.vue'
            ),
        },
      ],
    },
    {
      path: '/2fa/enroll/:mode(totp|sms)',
      name: 'twoFactorSetup',
      component: () =>
        import(
          /* webpackChunkName: "two-factor-setup" */ './views/main/general/TwoFactorSetup.vue'
        ),
      props: (route) => ({ mode: route.params.mode }), // pass the mode as a prop
    },
    {
      path: '/*',
      redirect: '/',
    },
  ],
  // @ts-ignore
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }

    return { x: 0, y: 0 };
  },
});
