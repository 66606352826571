var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-form',{ref:"form",on:{"input":_setup.updateIsValidForm},model:{value:(_setup.isValidForm),callback:function ($$v) {_setup.isValidForm=$$v},expression:"isValidForm"}},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t(_setup.sharedMessages.password)))]),_c('v-text-field',{attrs:{"id":"password","name":"password","type":_setup.showPassword ? 'text' : 'password',"value":_vm.password,"append-icon":_setup.showPassword ? 'mdi-eye' : 'mdi-eye-off',"error-messages":_vm.errors.first('password'),"rules":[_setup.required, _setup.isRightLengthPassword],"hint":_vm.$t(_setup.sharedMessages.passwordFormatInfo),"outlined":"","dense":""},on:{"input":_setup.updatePassword,"click:append":function($event){_setup.showPassword = !_setup.showPassword}}}),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t(_setup.sharedMessages.passwordConfirmation)))]),_c('v-text-field',{attrs:{"id":"passwordComfirmation","type":_setup.showPasswordConfirmation ? 'text' : 'password',"name":"passwordComfirmation","append-icon":_setup.showPasswordConfirmation ? 'mdi-eye' : 'mdi-eye-off',"value":_vm.passwordComfirmation,"rules":[
      _setup.isConfirmed(
        _vm.password,
        _vm.passwordComfirmation,
        _vm.$t(_setup.sharedMessages.passwordMismatch)
      ),
    ],"hint":_vm.$t(_setup.sharedMessages.passwordFormatInfo),"outlined":"","dense":""},on:{"click:append":function($event){_setup.showPasswordConfirmation = !_setup.showPasswordConfirmation},"input":_setup.updatePasswordComfirmation}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }