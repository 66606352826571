export class TemperatureUnitId {
  private static readonly _values: TemperatureUnitId[] = [];

  static readonly CELSIUS = new TemperatureUnitId(100, '℃', '℃', '℃');
  static readonly FAHRENHEIT = new TemperatureUnitId(101, '℉', '℉', '℉');
  static readonly KELVIN = new TemperatureUnitId(102, 'K', 'K', 'K');

  private constructor(
    readonly value: number,
    readonly text: string,
    readonly textJp: string,
    readonly textEn: string
  ) {
    TemperatureUnitId._values.push(this);
  }

  static fromId(value: number): TemperatureUnitId {
    const res = TemperatureUnitId._values.find((x) => x.value === value);
    if (res) {
      return res;
    } else {
      return TemperatureUnitId.CELSIUS;
    }
  }

  static values(): TemperatureUnitId[] {
    return TemperatureUnitId._values;
  }
}
