import { render, staticRenderFns } from "./SdsTableDrawer.vue?vue&type=template&id=574c326c&scoped=true"
import script from "./SdsTableDrawer.vue?vue&type=script&setup=true&lang=ts"
export * from "./SdsTableDrawer.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./SdsTableDrawer.vue?vue&type=style&index=0&id=574c326c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "574c326c",
  null
  
)

export default component.exports