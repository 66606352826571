// NOTE: DO NOT change the order of the colors
// because color saved in DB is based on the index of the colors array
export const colors = [
  { hex: '#F44336', rgb: '244,67,54' },
  { hex: '#E91E63', rgb: '233,30,99' },
  { hex: '#9C27B0', rgb: '156,39,176' },
  { hex: '#673AB7', rgb: '103,58,183' },
  { hex: '#3F51B5', rgb: '63,81,181' },
  { hex: '#1976D2', rgb: '25,118,210' },
  { hex: '#0288D1', rgb: '2,136,209' },
  { hex: '#00838F', rgb: '0,131,143' },
  { hex: '#00796B', rgb: '0,121,107' },
  { hex: '#2E7D32', rgb: '46,125,50' },
  { hex: '#689F38', rgb: '104,159,56' },
  { hex: '#AFB42B', rgb: '175,180,43' },
  { hex: '#FDD835', rgb: '253,216,53' },
  { hex: '#FFB300', rgb: '255,179,0' },
  { hex: '#F57C00', rgb: '245,124,0' },
  { hex: '#FF5722', rgb: '255,87,34' },
  { hex: '#795548', rgb: '121,85,72' },
  { hex: '#607D8B', rgb: '96,125,139' },
] as const;

export const getColor = (colorIndex: number | null) => {
  if (!colorIndex) {
    const defaultColor = { hex: '#009688', rgb: '0,150,136' };
    return defaultColor.rgb;
  }
  return colors[colorIndex - 1]?.rgb;
};
