import { initialCompanyState } from '@/lib/constructor/vuex/company';
import { NewUser } from '@/lib/constructor/vuex/user';
import { getPreferredLocale } from '@/lib/locales';
import { actionsMain } from './actionsMain';
import { getters } from './getters';
import { mutations } from './mutations';
import { MainState } from './state';

const defaultState: MainState = {
  isLoggedIn: null,
  token: '',
  company: { ...initialCompanyState },
  departments: [],
  userProfile: { ...NewUser },
  dashboardMiniDrawer: false,
  dashboardShowDrawer: true,
  notifications: [],
  locale: getPreferredLocale(),
};

export const mainModule = {
  state: defaultState,
  mutations,
  actions: { ...actionsMain },
  getters,
};
