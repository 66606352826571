var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"py-2"},[_c('div',{staticClass:"d-flex flex-column justify-content-start"},[_c('span',{staticClass:"mr-2 mb-1 body-2"},[_vm._v(_vm._s(_vm.$t(_setup.sharedMessages.currentSearchCriteria))+" :")]),_c('div',{staticClass:"d-flex align-center justify-content-start"},[_c('div',[(
            _vm.currentFilterParams.sdsName ||
            _vm.currentFilterParams.productName ||
            _vm.currentFilterParams.manufacturerName ||
            _vm.currentFilterParams.productCode
          )?_c('div',{staticClass:"d-flex align-center justify-content-start mb-1"},[(_vm.currentFilterParams.sdsName)?_c('v-chip',{staticClass:"mr-2",attrs:{"close":"","small":""},on:{"click:close":function($event){return _setup.handleDeleteFilterParam('sdsName')}}},[_vm._v(" "+_vm._s(_vm.$t(_setup.sharedMessages.sdsName))+": "+_vm._s(_vm.currentFilterParams.sdsName)+" ")]):_vm._e(),(_vm.currentFilterParams.productName)?_c('v-chip',{staticClass:"mr-2",attrs:{"close":"","small":""},on:{"click:close":function($event){return _setup.handleDeleteFilterParam('productName')}}},[_vm._v(" "+_vm._s(_vm.$t(_setup.sharedMessages.productName))+": "+_vm._s(_vm.currentFilterParams.productName)+" ")]):_vm._e(),(_vm.currentFilterParams.manufacturerName)?_c('v-chip',{staticClass:"mr-2",attrs:{"close":"","small":""},on:{"click:close":function($event){return _setup.handleDeleteFilterParam('manufacturerName')}}},[_vm._v(" "+_vm._s(_vm.$t(_setup.sharedMessages.manufacturers))+": "+_vm._s(_vm.currentFilterParams.manufacturerName)+" ")]):_vm._e(),(_vm.currentFilterParams.productCode)?_c('v-chip',{staticClass:"mr-2",attrs:{"close":"","small":""},on:{"click:close":function($event){return _setup.handleDeleteFilterParam('productCode')}}},[_vm._v(" "+_vm._s(_vm.$t(_setup.sharedMessages.productCode))+": "+_vm._s(_vm.currentFilterParams.productCode)+" ")]):_vm._e()],1):_vm._e(),(
            _vm.currentFilterParams.revisedAtStartDate ||
            _vm.currentFilterParams.revisedAtEndDate
          )?_c('div',{staticClass:"d-flex align-center justify-content-start mb-1"},[_c('span',{staticClass:"mr-2 mb-1 body-2"},[_vm._v(_vm._s(_vm.$t(_setup.sharedMessages.sdsRevisedAt))+" :")]),(_vm.currentFilterParams.revisedAtStartDate)?_c('v-chip',{staticClass:"mr-2",attrs:{"close":"","small":""},on:{"click:close":function($event){return _setup.handleDeleteFilterParam('revisedAtStartDate')}}},[_vm._v(" "+_vm._s(_vm.$t(_setup.sharedMessages.from))+": "+_vm._s(_vm.currentFilterParams.revisedAtStartDate)+" ")]):_vm._e(),(_vm.currentFilterParams.revisedAtEndDate)?_c('v-chip',{staticClass:"mr-2",attrs:{"close":"","small":""},on:{"click:close":function($event){return _setup.handleDeleteFilterParam('revisedAtEndDate')}}},[_vm._v(" "+_vm._s(_vm.$t(_setup.sharedMessages.until))+": "+_vm._s(_vm.currentFilterParams.revisedAtEndDate)+" ")]):_vm._e()],1):_vm._e(),(
            _vm.currentFilterParams.createdAtStartDate ||
            _vm.currentFilterParams.createdAtEndDate
          )?_c('div',{staticClass:"d-flex align-center justify-content-start mb-1"},[_c('span',{staticClass:"mr-2 mb-1 body-2"},[_vm._v(_vm._s(_vm.$t(_setup.sharedMessages.pdfUploadDate))+" :")]),(_vm.currentFilterParams.createdAtStartDate)?_c('v-chip',{staticClass:"mr-2",attrs:{"close":"","small":""},on:{"click:close":function($event){return _setup.handleDeleteFilterParam('createdAtStartDate')}}},[_vm._v(" "+_vm._s(_vm.$t(_setup.sharedMessages.from))+": "+_vm._s(_vm.currentFilterParams.createdAtStartDate)+" ")]):_vm._e(),(_vm.currentFilterParams.createdAtEndDate)?_c('v-chip',{staticClass:"mr-2",attrs:{"close":"","small":""},on:{"click:close":function($event){return _setup.handleDeleteFilterParam('createdAtEndDate')}}},[_vm._v(" "+_vm._s(_vm.$t(_setup.sharedMessages.until))+": "+_vm._s(_vm.currentFilterParams.createdAtEndDate)+" ")]):_vm._e()],1):_vm._e(),(_vm.currentFilterParams.statusGroupId.length)?_c('div',{staticClass:"d-flex align-center justify-content-start mb-1"},[_c('span',{staticClass:"body-2"},[_vm._v(_vm._s(_vm.$t(_setup.sharedMessages.status))+" : ")]),_vm._v("   "),_vm._l((_vm.currentFilterParams.statusGroupId),function(statusId){return _c(_setup.StateChip,{key:statusId,class:`${_setup.statusChipClass(statusId)} mr-2`,attrs:{"value":_setup.props.status.find((state) => state.value === statusId)?.value ||
              statusId},on:{"closeEvent":function($event){return _setup.handleDeleteFilterFromArrayParam('statusGroupId', statusId)}}})})],2):_vm._e(),(_vm.currentFilterParams.uploadUsers.length)?_c('div',{staticClass:"d-flex align-center justify-content-start mb-1"},[_c('span',{staticClass:"body-2"},[_vm._v(_vm._s(_vm.$t(_setup.sharedMessages.uploadUser))+" : ")]),_vm._v("   "),_vm._l((_vm.currentFilterParams.uploadUsers),function(userId,index){return _c('v-chip',{key:`user-${index}`,staticClass:"mr-2",attrs:{"close":"","small":""},on:{"click:close":function($event){return _setup.handleDeleteFilterFromArrayParam('uploadUsers', userId)}}},[_vm._v(" "+_vm._s(_setup.getUserName(userId))+" ")])})],2):_vm._e(),(
            _vm.currentFilterParams.tagIds.length &&
            _setup.props.tags.some((tag) =>
              _vm.currentFilterParams.tagIds.includes(tag.id)
            )
          )?_c('div',{staticClass:"d-flex align-center justify-content-start mb-1"},[_c('span',{staticClass:"body-2"},[_vm._v(_vm._s(_vm.$t(_setup.sharedMessages.tag))+" : ")]),_vm._v("   "),_vm._l((_vm.currentFilterParams.tagIds),function(tagId){return _c(_setup.TagChip,{key:tagId,attrs:{"tag":_vm.tags.find((tag) => tag.id === tagId) || _setup.props.tags[0],"add-class":"mr-2"},on:{"closeEvent":function($event){return _setup.handleDeleteFilterFromArrayParam('tagIds', tagId)}}})})],2):_vm._e(),(_vm.currentFilterParams.departmentUuids.length)?_c('div',{staticClass:"d-flex align-center justify-content-start mb-1"},[_c('span',{staticClass:"body-2"},[_vm._v(_vm._s(_vm.$t(_setup.sharedMessages.department))+" : ")]),_vm._v("   "),_vm._l((_vm.currentFilterParams.departmentUuids),function(departmentUuid){return _c('v-chip',{key:departmentUuid,staticClass:"mr-2",attrs:{"close":"","small":""},on:{"click:close":function($event){return _setup.handleDeleteFilterFromArrayParam(
                'departmentUuids',
                departmentUuid
              )}}},[_vm._v(" "+_vm._s(_setup.getDepartmentName(departmentUuid))+" ")])})],2):_vm._e(),(_vm.currentFilterParams.isLatestOnly)?_c('div',{staticClass:"d-flex align-center justify-content-start mb-1"},[_c('v-chip',{staticClass:"mr-2",attrs:{"close":"","small":""},on:{"click:close":function($event){return _setup.handleDeleteFilterParam('isLatestOnly')}}},[_vm._v(" "+_vm._s(_vm.$t(_setup.sharedMessages.latestOnly))+" ")])],1):_vm._e()]),_c('v-spacer'),_c('v-btn',{staticClass:"text-decoration-underline",attrs:{"text":""},on:{"click":_vm.resetFilters}},[_vm._v(" "+_vm._s(_vm.$t(_setup.sharedMessages.clearFilters))+" ")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }