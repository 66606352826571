var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-simple-table',{staticClass:"table-content"},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.$t(_setup.sharedMessages.tagName)))]),_c('th',{staticClass:"text-left"})])]),_c('tbody',{staticClass:"align-center"},_vm._l((_vm.tags),function(tag){return _c('tr',{key:tag.id},[_c('td',[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v(" mdi-tag ")]),_c('v-chip',{style:({
              backgroundColor: `rgba(${_setup.getColor(tag.color)}, 0.24)`,
              color: `rgba(${_setup.getColor(tag.color)}, 1)`,
            })},[_vm._v(" "+_vm._s(tag.name)+" ")])],1),_c('td',[_c('div',{staticClass:"d-flex align-center justify-end"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(tooltipEditor){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"disabled":!_setup.hasPermission(_setup.P.TAG_EDIT),"icon":"","x-small":"","color":"primary"},on:{"click":function($event){return _setup.openUpdateDialog(tag)}}},'v-btn',{
                    ...tooltipEditor.attrs,
                  },false),{ ...tooltipEditor.on }),[_c('v-icon',[_vm._v("mdi-square-edit-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t(_setup.m.editTag)))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(tooltipActivator){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"disabled":!_setup.hasPermission(_setup.P.TAG_DELETE),"icon":"","x-small":"","color":"error"},on:{"click":function($event){return _setup.openDeleteDialog(tag)}}},'v-btn',{
                    ...tooltipActivator.attrs,
                  },false),{ ...tooltipActivator.on }),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t(_setup.m.deleteTag)))])])],1)])])}),0)]),_c(_setup.CompanyTagFormDialog,{attrs:{"selected-tag":_setup.selectedTag},on:{"submitted":() => _setup.emits('dataChanged')},model:{value:(_setup.showTagFormDialog),callback:function ($$v) {_setup.showTagFormDialog=$$v},expression:"showTagFormDialog"}}),_c(_setup.CompanyTagDeleteDialog,{attrs:{"selected-tag":_setup.selectedTag},on:{"deleted":() => _setup.emits('dataChanged')},model:{value:(_setup.showTagDeleteDialog),callback:function ($$v) {_setup.showTagDeleteDialog=$$v},expression:"showTagDeleteDialog"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }