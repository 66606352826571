import {
  CompanyDepartmentCreateRequestBody,
  CompanyDepartmentUpdateRequestBody,
} from '@/interfaces/api/general/company';
import { generalApi } from '@/lib/api/general';
import {
  dispatchCheckApiError,
  dispatchLogOut,
  MainContext,
} from '@/store/main/actionsMain';
import {
  commitAddNotification,
  commitSetDepartments,
} from '@/store/main/mutations';
import { defineMessages } from '@/lib/i18n';
import { AxiosError } from 'axios';

const m = defineMessages({
  companyDepartmentCreated: '部署を作成しました',
  companyDepartmentUpdated: '部署を更新しました',
  companyDepartmentDeleted: '部署を削除しました',
});

export const actionGetCompanyDepartments = async (
  context: MainContext,
  payload: { companyId: string }
) => {
  try {
    const response = await generalApi.getCompanyDepartments(
      context.state.token,
      payload.companyId
    );
    if (response.data) {
      commitSetDepartments(context, [...response.data]);
    }
  } catch (error) {
    const axiosError = error as AxiosError;
    await dispatchCheckApiError(context, axiosError);
    if (axiosError?.response?.status === 403) {
      await dispatchLogOut(context);
    }
    throw axiosError;
  }
};

export const actionCreateCompanyDepartment = async (
  context: MainContext,
  payload: {
    data: CompanyDepartmentCreateRequestBody;
    companyId: string;
  }
) => {
  try {
    const response = await generalApi.createCompanyDepartment(
      context.state.token,
      payload.companyId,
      payload.data
    );
    if (response) {
      commitAddNotification(context, {
        messageKey: m.companyDepartmentCreated,
        color: 'success',
      });
    }
  } catch (error) {
    const axiosError = error as AxiosError;
    await dispatchCheckApiError(context, axiosError);
    if (axiosError?.response?.status === 403) {
      await dispatchLogOut(context);
    }
    throw axiosError;
  }
};

export const actionUpdateCompanyDepartment = async (
  context: MainContext,
  payload: {
    companyId: string;
    departmentId: string;
    data: CompanyDepartmentUpdateRequestBody;
  }
) => {
  try {
    const response = await generalApi.updateCompanyDepartment(
      context.state.token,
      payload.companyId,
      payload.departmentId,
      payload.data
    );
    if (response) {
      commitAddNotification(context, {
        messageKey: m.companyDepartmentUpdated,
        color: 'success',
      });
    }
  } catch (error) {
    const axiosError = error as AxiosError;
    await dispatchCheckApiError(context, axiosError);
    if (axiosError?.response?.status === 403) {
      await dispatchLogOut(context);
    }
    throw axiosError;
  }
};

export const actionDeleteCompanyDepartment = async (
  context: MainContext,
  payload: {
    companyId: string;
    departmentId: string;
  }
) => {
  try {
    const response = await generalApi.deleteCompanyDepartment(
      context.state.token,
      payload.companyId,
      payload.departmentId
    );
    if (response) {
      commitAddNotification(context, {
        messageKey: m.companyDepartmentDeleted,
        color: 'success',
      });
    }
  } catch (error) {
    const axiosError = error as AxiosError;
    await dispatchCheckApiError(context, axiosError);
    if (axiosError?.response?.status === 403) {
      await dispatchLogOut(context);
    }
    throw axiosError;
  }
};
