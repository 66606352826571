import { computed, ref } from 'vue';
import {
  CompanyDepartmentCreateRequestBody,
  CompanyDepartmentUpdateRequestBody,
} from '@/interfaces/api/general/company';
import store from '@/store';
import {
  dispatchCreateCompanyDepartment,
  dispatchDeleteCompanyDepartment,
  dispatchGetCompanyDepartments,
  dispatchUpdateCompanyDepartment,
} from '@/store/main/actionsMain';
import {
  readCompanyId,
  readDepartments,
  readUserProfile,
} from '@/store/main/getters';

export const useDepartmentComposable = () => {
  const isFetching = ref(false);
  const isCreating = ref(false);
  const isUpdating = ref(false);
  const isDeleting = ref(false);

  const getCompanyDepartments = async (): Promise<void> => {
    isFetching.value = true;
    try {
      await dispatchGetCompanyDepartments(store, {
        companyId: readCompanyId(store),
      });
    } catch (e) {
      console.error(e);
    } finally {
      isFetching.value = false;
    }
  };

  const createCompanyDepartment = async (
    data: CompanyDepartmentCreateRequestBody
  ): Promise<void> => {
    isCreating.value = true;
    try {
      await dispatchCreateCompanyDepartment(store, {
        companyId: readCompanyId(store),
        data,
      });
    } catch (e) {
      console.error(e);
    } finally {
      isCreating.value = false;
    }
  };

  const updateCompanyDepartment = async (
    departmentId: string,
    data: CompanyDepartmentUpdateRequestBody
  ): Promise<void> => {
    isUpdating.value = true;
    try {
      await dispatchUpdateCompanyDepartment(store, {
        companyId: readCompanyId(store),
        departmentId: departmentId,
        data,
      });
    } catch (e) {
      console.error(e);
    } finally {
      isUpdating.value = false;
    }
  };

  const deleteCompanyDepartment = async (
    departmentId: string
  ): Promise<void> => {
    isDeleting.value = true;
    try {
      await dispatchDeleteCompanyDepartment(store, {
        companyId: readCompanyId(store),
        departmentId: departmentId,
      });
    } catch (e) {
      console.error(e);
    } finally {
      isDeleting.value = false;
    }
  };

  const companyDepartments = computed(() => readDepartments(store));

  const ownDepartments = computed(() => {
    const userDepartmentUuidList = readUserProfile(store).departments.map(
      (d) => d.uuid
    );
    const companyDepartments = readDepartments(store);
    return companyDepartments.filter((d) =>
      userDepartmentUuidList.includes(d.uuid)
    );
  });

  const isOwnDepartment = (departmentId: string): boolean => {
    const userDepartments = readUserProfile(store).departments;
    return userDepartments.some(
      (userDepartment) => userDepartment.uuid === departmentId
    );
  };

  return {
    isFetching,
    isCreating,
    isUpdating,
    isDeleting,
    getCompanyDepartments,
    createCompanyDepartment,
    updateCompanyDepartment,
    deleteCompanyDepartment,
    companyDepartments,
    ownDepartments,
    isOwnDepartment,
  };
};
