export const availableLocales: string[] = ['ja-JP', 'en-US'];

export const getPreferredLocale = (): string => {
  // Get the array of preferred languages or fallback to an empty array
  const userLocales: string[] = [
    ...(navigator.languages || [navigator.language]),
  ];

  // If no language information is available, default to Japanese
  if (!userLocales || userLocales.length === 0 || !userLocales[0]) {
    return 'ja-JP';
  }

  // Check for an exact match or base language match
  for (const locale of userLocales) {
    // Exact match
    if (availableLocales.includes(locale)) {
      return locale;
    }

    // Base language match (e.g., "zh" matches "zh-CN")
    const baseLanguage = locale.split('-')[0];
    const baseLocale = availableLocales.find((available) =>
      available.startsWith(baseLanguage)
    );
    if (baseLocale) {
      return baseLocale;
    }
  }

  // Additional fallback rules
  const hasChinese = userLocales.some((locale) => locale.startsWith('zh'));
  const hasEnglish = userLocales.some((locale) => locale.startsWith('en'));

  // Prioritize Japanese if Chinese is present and English is not
  if (hasChinese && !hasEnglish) {
    return 'ja-JP';
  }

  // Default to English if Japanese is not preferred
  return 'en-US';
};
