import { defineMessages } from '@/lib/i18n';

const m = defineMessages({
  roleViewer: '閲覧者',
  roleDownloadableViewer: 'ダウンロード閲覧者',
  roleEditor: '編集者',
  roleAdmin: '管理者',
});

export class RoleId {
  private static readonly _values: RoleId[] = [];

  static readonly USER_VIEWER = new RoleId(0, m.roleViewer);
  static readonly USER_DOWNLOADABLE_VIEWER = new RoleId(
    1,
    m.roleDownloadableViewer
  );
  static readonly USER_EDITOR = new RoleId(90, m.roleEditor);
  static readonly USER_ADMIN = new RoleId(100, m.roleAdmin);

  private constructor(readonly value: number, readonly messageId: string) {
    RoleId._values.push(this);
  }

  static fromRoleId(value: number): RoleId {
    const res = RoleId._values.find((x) => x.value === value);
    if (res) {
      return res;
    } else {
      return RoleId.USER_VIEWER;
    }
  }

  static values(): RoleId[] {
    return RoleId._values;
  }
}
