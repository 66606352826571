import { generalApi } from '@/lib/api/general';
import { MainContext } from '@/store/main/actionsMain';
import { commitAddNotification } from '@/store/main/mutations';
import { defineMessages } from '@/lib/i18n';

const m = defineMessages({
  signUpSuccess:
    '入力されたメールアドレスにアカウント登録完了用のメールを送信しました。メールボックスをご確認いただき、24時間以内にアカウント登録完了をお願いいたします。',
  signUpFailed:
    '新規登録に失敗しました。このアドレスは登録済みの可能性があります。',
});

export const actionSignUp = async (
  context: MainContext,
  payload: {
    first_name: string;
    last_name: string;
    middle_name: string;
    email: string;
    password: string;
    company_name: string;
    department: string;
    position: string;
    phone: string;
    g_recaptcha_response: string;
  }
) => {
  try {
    await generalApi.signUp(payload);
    commitAddNotification(context, {
      messageKey: m.signUpSuccess,
      color: 'success',
    });
  } catch (err) {
    commitAddNotification(context, {
      messageKey: m.signUpFailed,
      color: 'error',
    });
    throw err;
  }
};
