import { defineMessages } from '@/lib/i18n';

// Keep these sorted alphabetically.

export default defineMessages({
  add: '追加',
  addAction: '追加する',
  addBoilingPoint: '沸点を追加',
  addChemical: '化学物質を追加',
  addComponentInfo: '成分情報を追加',
  addExposureLimit: '暴露限界値・許容濃度を追加',
  addImporterInfo: '輸入者・販売者情報を追加',
  addManufacturer: '製造者情報を追加',
  addPhysicalChemicalProperties: '物理的・化学的性質を追加',
  addSummaryInfo: '危険有害性の要約情報の追加',
  addTagName: 'タグ名の追加',
  address: '住所',
  addressLong: '住所 / Address',
  analyzing: '分析中',
  and: '及び',
  apply: '適用する',
  back: '戻る',
  bulkEdit: '一括編集',
  cancel: 'キャンセル',
  casNumber: 'CAS番号',
  casNumberLong: 'CAS番号 / CAS Number・CAS RN',
  category: '区分',
  change: '変更する',
  checkFrequency: 'チェックは1日1回、深夜に行われます。',
  checklist: 'チェックリスト',
  checklistAddDate: 'チェックリスト追加日',
  chemical: '化学物質',
  chemicalName: '化学物質名 / Chemical Name',
  chemican: 'ケミカン',
  clearFilters: 'フィルターをクリアする',
  close: '閉じる',
  color: 'カラー',
  company: '運営会社',
  companyIdentification: '社名 / Company Name・Company Identification',
  companyName: '企業名',
  confirmDelete: '本当に削除しますか',
  confirmLeave: '編集中の作業は保存されませんが、よろしいですか？',
  contactUs: 'お問い合わせ',
  create: '作成する',
  createDepartment: '部署を作成する',
  createDepartmentTitle: '部署を作成',
  createDepartmentTooltip: '新しく部署を作成します',
  createFromLaws: '法令から作成する',
  createFromMaterials: '物質から作成する',
  creationDate: '作成日',
  creator: '作成者',
  csvDownload: 'CSVダウンロード',
  currentSearchCriteria: '現在の検索条件',
  dataToDelete: '削除するデータ',
  default: '標準',
  defaultUser: 'ケミカン運営',
  delete: '削除',
  deleteAction: '削除する',
  deleteDepartment: 'この部署を削除します',
  deleteInstruction:
    '「削除する」をクリックすると、データに削除フラグが付与されます。付与された削除フラグは、画面上部の「保存」をクリックすることでデータベースに反映されます。',
  deleteProduct: '製品を削除しますか',
  deleteThisIpAddress: 'このIPアドレスを削除',
  deleteUser: 'ユーザーを削除しますか？',
  deleteWarning:
    '「削除する」をクリックすると、データがDBから削除されます。この操作は戻すことができません。',
  deletedSdsWarning: 'ユーザー側で削除済みのSDS',
  department: '担当部署',
  departmentInfo:
    '追加できるのは自分が所属している部署のみです。{br}管理者はすべての部署が選択できます。',
  departmentName: '部署名',
  departments: '部署',
  documentCounter: '{count}件',
  edit: '編集',
  editManufacturerData: '第1章製造者のデータを編集してください',
  editPrompt: '編集を行なってください',
  email: 'メールアドレス',
  emailLabel: 'メールアドレス / Email Address',
  enableSmsAuth: 'SMS認証を有効にする',
  enterExposureLimit: '追加する暴露限界値を入力してください',
  equal: 'と等しい(=)',
  execute: '実行する',
  export: 'エクスポート',
  features: '機能',
  filter: 'フィルター',
  firstName: '名',
  fiveYearsPassed: '5年以上経過しています',
  freePlan: 'フリープラン',
  freeTrial: '無料トライアル',
  from: '開始',
  fullName: '氏名',
  genericError: 'エラーが発生しました。もう一度お試しください。',
  greaterThan: 'より大きい(>)',
  greaterThanLess: 'より大きい(>)、未満(<)',
  greaterThanLessOrEqual: 'より大きい(>)、以下(≦)',
  greaterThanOrEqual: '以上(≧)',
  greaterThanOrEqualLess: '以上(≧)、未満(<)',
  greaterThanOrEqualLessOrEqual: '以上(≧)、以下(≦)',
  invite: '招待',
  itemsPerPageText: '行/ページ:',
  lastName: '姓',
  lastUpdatedDate: '最終更新日',
  latestOnly: '同一判定された商品のSDSは最新版のみ表示する',
  law: '法令',
  lessThan: '未満(<)',
  lessThanOrEqual: '以下(≦)',
  checklistCompletionStatus:
    'リストチェック完了：{lastCount}件/{uploadedTotal}件、次回更新日時：{nextUpdateDate}',
  listName: 'リスト名',
  login: 'ログイン',
  logoAlt: 'ケミカン - SDSを正確にデータ化',
  manufacturers: '製造者',
  match: '該当',
  measurementMethod: '計測方法・測定方法・方法',
  middleName: 'ミドルネーム',
  more: '件',
  name: '名前',
  nameForManagement: '名前(管理用)',
  newAddition: '新規追加',
  newSds: '新規SDS',
  noDataText: 'データが登録されていません',
  noLawRegulation: '該当法令なし',
  noMatch: '非該当',
  noTagsFound: 'タグが見つかりませんでした',
  noTagsRegisteredInfo:
    '登録されているタグがありません。<br /> タグの登録は、{registerLink} から行うことができます。',
  none: 'なし',
  nonenPublic: '非公開',
  note: 'メモ',
  ok: 'OK',
  operationIrreversible: 'この操作は取り消すことができません',
  other: 'その他',
  pageSelect: 'ページ選択',
  password: 'パスワード',
  passwordConfirmation: 'パスワード(確認用)',
  passwordMismatch: 'パスワードが一致しません',
  passwordReset: 'パスワードの再発行',
  passwordResetAction: 'パスワードを再発行',
  passwordFormatInfo:
    '8文字以上かつ半角英大文字・半角英小文字・半角数字・記号(!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~)をそれぞれ最低1回以上使用してください',
  passwordFormatInfoLogin:
    '8文字以上の大小半角英数字記号(!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~)で入力してください',
  pdfDisplay: 'PDFの表示',
  pdfName: 'PDF名',
  pdfUploadDate: 'PDFアップロード日',
  phoneNumber: '電話番号',
  phoneLabel:
    '連絡先（電話）/ Phone・TEL・Emergency TEL(キーボード入力後、Enterキーを押すと複数の値を入力できます)',
  contactPhoneNumber: '連絡先電話番号',
  position: '役職',
  positionOrTitle: '役職・職位（役職がない場合は「なし」と記載ください',
  present: 'あり',
  pricing: '価格',
  privacyPolicy: 'プライバシーポリシー',
  productCode: '製品コード',
  productName: '製品名',
  productNameChange: '製品名の変更(製品名を入力してください)',
  reanalysisPrompt: '問題のあった項目、不足点を記入してください',
  reanalysisRequest: '再データ化依頼',
  register: '無料で試してみる',
  removeDeleteFlag: '削除フラグを外す',
  removeDeleteFlagTitle: '第8章のデータの削除フラグを外しますか',
  rescueWarning:
    '「削除フラグを外す」をクリックすると、データに付与された削除フラグが外されます。',
  resendCode: 'コードを再送信',
  role: '権限',
  sdsName: 'SDS名',
  sdsRevisedAt: 'SDS改訂日',
  sdsTotalRegistered:
    '登録されているSDS総数のうち、データ化が完了しているSDS数がチェックの対象となります。',
  search: '検索する',
  searchDepartment: '部署を検索',
  searchTags: 'タグを検索',
  selectPlease: '選択してください',
  selectionHint:
    '選択肢の中から選択または文字入力のどちらかを選んでEnterキーを押してください',
  skinProtection: '皮膚の保護具',
  status: 'ステータス',
  statusCreated: '作成済み',
  statusInPreparation: '作成中',
  statusUpdateSuspended: '更新中止',
  subjectDeleteConfirmation: '{subject}を削除しますか',
  summary: '概要',
  tag: 'タグ',
  tagListLink: 'タグ一覧',
  tagName: 'タグ名',
  targetLawRegulation: '対象法令 / 細則',
  targetSDS: '対象SDS',
  termsOfService: '利用規約',
  totalItems: '全{total}件',
  twoFactorAuthentication: '二要素認証',
  unNumber: 'UN番号',
  unit: '単位',
  unlimited: '上限なし',
  until: '終了',
  update: '更新する',
  updateTag: 'タグを更新する',
  uploadUser: 'アップロードユーザー',
  usageGuide: '使い方ガイド',
  valuesEqual: '{value}{unit}と等しい(=)',
  valuesGreaterThanOrEqual: '{value}{unit}以上(≧)',
  valuesGreaterThan: '{value}{unit}より大きい(>)',
  valuesLessThanOrEqual: '{value}{unit}以下(≦)',
  valuesLessThan: '{value}{unit}未満(<)',
  valuesBetweenInclusive: '{minValue}{unit}以上(≧), {maxValue}{unit}以下(≦)',
  valuesBetweenExclusiveInclusive:
    '{minValue}{unit}以上(≧), {maxValue}{unit}未満(<)',
  valuesBetweenExclusive:
    '{minValue}{unit}より大きい(>), {maxValue}{unit}以下(≦)',
  valuesBetweenExclusiveExclusive:
    '{minValue}{unit}より大きい(>), {maxValue}{unit}未満(<)',
  whitelistIpAddress: 'ホワイトリストIPアドレス',
  categoryDustRespirable: '吸引性区分',
  mgPerM3: 'mg/m³',
  ppm: 'ppm',
  materialName: '成分名',
  cause: '要因',
});
