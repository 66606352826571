var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-simple-table',{staticClass:"table-content"},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.$t(_setup.sharedMessages.departmentName)))]),_c('th')])]),_c('tbody',_vm._l((_vm.departments),function(department){return _c('tr',{key:department.name},[_c('td',[_vm._v(" "+_vm._s(department.name)+" ")]),_c('td',[_c('div',{staticClass:"d-flex align-center justify-end"},[_c('v-dialog',{attrs:{"value":_setup.updateTarget?.uuid === department.uuid,"width":"500","retain-focus":false},scopedSlots:_vm._u([{key:"activator",fn:function(dialogActivator){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(tooltipActivator){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"disabled":!_setup.hasPermission(_setup.P.DEPARTMENT_EDIT),"icon":"","x-small":"","color":"primary"},on:{"click":function($event){return _setup.showUpdateDialog(department)}}},'v-btn',{
                        ...tooltipActivator.attrs,
                        ...dialogActivator.attrs,
                      },false),{ ...tooltipActivator.on, ...dialogActivator.on }),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t(_setup.m.editDepartmentName)))])])]}}],null,true)},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 lighten-2"},[_vm._v(" "+_vm._s(_vm.$t(_setup.m.editDepartmentNameTitle))+" ")]),_c('v-card-text',[_c('v-form',{ref:"form",refInFor:true,on:{"input":function($event){_setup.isValidForm = $event}},model:{value:(_setup.isValidForm),callback:function ($$v) {_setup.isValidForm=$$v},expression:"isValidForm"}},[_c('v-text-field',{attrs:{"value":_setup.updateDepartmentForm.name,"rules":[_setup.required, _setup.isDepartmentName, _setup.hasNoComma],"name":"name","label":_vm.$t(_setup.sharedMessages.departmentName),"type":"text","outlined":"","dense":""},on:{"input":function($event){_setup.updateDepartmentForm.name = $event}}})],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"cancel","text":"","loading":_setup.isUpdating},on:{"click":function($event){_setup.updateTarget = undefined}}},[_vm._v(_vm._s(_vm.$t(_setup.sharedMessages.cancel)))]),_c('v-btn',{attrs:{"color":"primary","text":"","disabled":!_setup.isValidForm,"loading":_setup.isUpdating},on:{"click":function($event){return _setup.updateDepartment(department)}}},[_vm._v(" "+_vm._s(_vm.$t(_setup.sharedMessages.update))+" ")])],1)],1)],1),_c('v-dialog',{attrs:{"value":_setup.deleteTarget?.uuid === department.uuid,"width":"500","retain-focus":false},scopedSlots:_vm._u([{key:"activator",fn:function(dialogActivator){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(tooltipActivator){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"disabled":!_setup.hasPermission(_setup.P.DEPARTMENT_DELETE),"icon":"","x-small":"","color":"error"},on:{"click":function($event){_setup.deleteTarget = department}}},'v-btn',{
                        ...tooltipActivator.attrs,
                        ...dialogActivator.attrs,
                      },false),{ ...tooltipActivator.on, ...dialogActivator.on }),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t(_setup.sharedMessages.deleteDepartment)))])])]}}],null,true)},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 lighten-2"},[_vm._v(_vm._s(_vm.$t(_setup.m.deleteDepartmentTitle)))]),_c('v-card-text',[_vm._v(_vm._s(_vm.$t(_setup.sharedMessages.operationIrreversible)))]),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"error","text":"","loading":_setup.isDeleting},on:{"click":function($event){return _setup.deleteDepartment(department)}}},[_vm._v(_vm._s(_vm.$t(_setup.sharedMessages.deleteAction)))]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":"","loading":_setup.isDeleting},on:{"click":function($event){_setup.deleteTarget = undefined}}},[_vm._v(_vm._s(_vm.$t(_setup.sharedMessages.cancel)))])],1)],1)],1)],1)])])}),0)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }