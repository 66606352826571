export class PlanId {
  private static readonly _values: PlanId[] = [];
  static readonly FREE = new PlanId(0, 0, 10);
  static readonly PAID_100 = new PlanId(100, 15_000, 100);
  static readonly PAID_250 = new PlanId(250, null, 250);
  static readonly PAID_300 = new PlanId(300, null, 300);
  static readonly PAID_500 = new PlanId(500, null, 500);
  static readonly PAID_1000 = new PlanId(1000, 40_000, 1_000);
  static readonly PAID_2500 = new PlanId(2500, 60_000, 2_500);
  static readonly PAID_4000 = new PlanId(4000, 40_000, 4_000);
  static readonly PAID_5000 = new PlanId(5000, 75_000, 5_000);
  static readonly PAID_7500 = new PlanId(7500, 90_000, 7_500);
  static readonly PAID_10000 = new PlanId(10000, 100_000, 10_000);
  static readonly PAID_15000 = new PlanId(15000, null, 15_000);
  static readonly PAID_20000 = new PlanId(20000, null, 20_000);
  static readonly PAID_30000 = new PlanId(30000, null, 30_000);
  static readonly PAID_40000 = new PlanId(40000, null, 40_000);
  static readonly PAID_50000 = new PlanId(50000, null, 50_000);
  static readonly PAID_UNLIMITED = new PlanId(
    1_000_000_000,
    Infinity,
    Infinity
  );

  private constructor(
    readonly value: number,
    readonly price: number | null,
    readonly maxUpload: number
  ) {
    PlanId._values.push(this);
  }

  static fromPlanId(value: number): PlanId {
    const res = PlanId._values.find((x) => x.value === value);
    if (res) {
      return res;
    } else {
      return PlanId.FREE;
    }
  }

  static values(): PlanId[] {
    return PlanId._values;
  }
}
