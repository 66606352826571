import { countString } from '@/utils/string';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import { FormItem } from '@/interfaces/lib/model';
import {
  companyNamePattern,
  departmentNamePattern,
  ipV4Regex,
  ipV6Regex,
  nullCharacterRegex,
  patternNoComma,
  patternNumberAndAlphabetsAndHyphen,
  patternNumberAndHyphen,
  patternPassword,
  patternPasswordForLogin,
  patternPdfExtension,
  positionNamePattern,
  patternEmailAddress,
  patternFreeEmailDomain,
} from '@/lib/regex';
import { defineMessages } from '@/lib/i18n';
import sharedMessages from '@/lib/shared-messages';
import { i18n } from '@/plugins/vue-i18n';

const $t = i18n.tc.bind(i18n);

const m = defineMessages({
  required: '必須',
  invalidEmail:
    'メールアドレスの入力形式が間違っています。正しいメールアドレスを入力してください。',
  invalidFreeEmail:
    'ご入力いただいたメールアドレスは無効です。独自ドメインのメールアドレスを使用してください。',
  mismatchConfirmation: '入力内容が一致しません',
  invalidPhone:
    '半角数字（半角ハイフンなし）で入力してください。半角数字＋半角ハイフンへ自動変換されます。',
  invalidCompanyName:
    '企業名が無効です。企業名には日本語、アルファベット、数字等を使用してください（特殊文字等は文頭で使用できません）。',
  meaninglessCompanyName:
    '「-（ハイフン）」「ー（ハイフン）」「なし」「個人」などの企業名は無効です。正しい企業名を入力してください。',
  invalidDepartmentName:
    '部署名が無効です。部署名には日本語、アルファベット、数字等を使用してください（特殊文字等は文頭で使用できません）。',
  invalidPositionName:
    '役職・職位が無効です。役職・職位には日本語、アルファベット、数字等を使用してください（特殊文字等は文頭で使用できません）。',
  alphanumAndHyphenOnly: '半角英数字とハイフンのみ使用可能です',
  numberAndHyphenOnly: '半角数字とハイフンのみ使用可能です',
  invalidIp: 'IPアドレスの形式が正しくありません',
  invalidPdfExtension: '拡張子が.pdfではありません',
  noCommaAllowed: 'カンマ（,）は使用できません。',
  invalidOtp: '6桁の数字を入力してください',
  invalidSpaces:
    '有効でないスペースが含まれています。手入力で再度入力してください。',
  numbersAndDecimalsOnly: '半角数字(小数を含む)のみ使用可能です',
  notLongerThan: '{length}文字以内で入力してください',
});

export const required = (
  value: string | number | boolean | undefined
): boolean | string => {
  const v = typeof value === 'number' ? value.toString(10) : value;
  return !!v || $t(m.required);
};
export const requiredSelection = (
  value: Array<string | number | boolean | undefined>
): boolean | string => value.length > 0 || $t(m.required);

export const isRightEmailAddress = (value: string): boolean | string =>
  !!value.match(patternEmailAddress) || $t(m.invalidEmail);

export const isNotFreeEmailDomain = (value: string): boolean | string =>
  !!value.match(patternFreeEmailDomain) || $t(m.invalidFreeEmail);

export const isRightLengthPassword = (value: string): boolean | string =>
  !!value.match(patternPassword) || $t(sharedMessages.passwordFormatInfo);

export const isRightLengthPasswordForLogin = (
  value: string
): boolean | string =>
  !!value.match(patternPasswordForLogin) ||
  $t(sharedMessages.passwordFormatInfoLogin);

export const isConfirmed = (
  input: string,
  inputConfirmation: string,
  text: string
): boolean | string =>
  input == inputConfirmation || text || $t(m.mismatchConfirmation);

export const phoneNumber = (value: string): boolean | string => {
  if (!value) return true;
  const phoneNumber = parsePhoneNumberFromString(value, 'JP');
  return (phoneNumber && phoneNumber.isValid()) || $t(m.invalidPhone);
};

export const isCompanyName = (value: string): boolean | string =>
  !!value.match(companyNamePattern) || $t(m.invalidCompanyName);

export const isMeaningfulCompanyName = (value: string): boolean | string =>
  !(value === '-' || value === 'ー' || value === 'なし' || value === '個人') ||
  $t(m.meaninglessCompanyName);

export const isDepartmentName = (value: string): boolean | string =>
  value === '' ||
  !!value.match(departmentNamePattern) ||
  $t(m.invalidDepartmentName);

export const isPositionName = (value: string): boolean | string =>
  !value || !!value.match(positionNamePattern) || $t(m.invalidPositionName);

export const isNumberAndHyphen = (value: string): boolean | string =>
  !!value.match(patternNumberAndHyphen) || $t(m.alphanumAndHyphenOnly);

export const isNumberAndHyphenArray = (
  value: Array<string>
): boolean | string => {
  let valid = true;

  for (const v of value) {
    if (!v.match(patternNumberAndHyphen)) {
      valid = false;
      break;
    }
  }

  return valid || $t(m.numberAndHyphenOnly);
};

export const isNumberAndAlphabetsAndHyphenArray = (
  value: Array<string>
): boolean | string => {
  let valid = true;

  for (const v of value) {
    if (!v.match(patternNumberAndAlphabetsAndHyphen)) {
      valid = false;
      break;
    }
  }

  return valid || $t(m.alphanumAndHyphenOnly);
};

export const isIpAddress = (value: string): boolean | string =>
  !!new RegExp(`(?:^${ipV4Regex}$)|(?:^${ipV6Regex}$)`).test(value) ||
  $t(m.invalidIp);

export const hasNoNullText = (value: string): boolean | string =>
  !new RegExp(nullCharacterRegex).test(value) || $t(m.invalidSpaces);

export const hasNoNullTextArray = (value: Array<string>): boolean | string => {
  for (const v of value) {
    if (new RegExp(nullCharacterRegex).test(v)) {
      return $t(m.invalidSpaces);
    }
  }

  return true;
};

export const hasNoNullTextItem = (value: FormItem): boolean | string => {
  if (new RegExp(nullCharacterRegex).test(value.text)) {
    return $t(m.invalidSpaces);
  }

  return true;
};

export const isFloat = (value: string | number | undefined): boolean | string =>
  !!String(value).match(/^[-]?\d+(\.\d+)?$/) || $t(m.numbersAndDecimalsOnly);

export const isFloatOrNull = (
  value: string | number | undefined
): boolean | string => {
  if (value == null) {
    return true;
  }
  return (
    !!String(value).match(/^[-]?\d+(\.\d+)?$/) ||
    !!value == null ||
    $t(m.numbersAndDecimalsOnly)
  );
};

export const hasPdfExtension = (value: string): boolean | string =>
  new RegExp(patternPdfExtension).test(value) || $t(m.invalidPdfExtension);

export const hasNoComma = (value: string): boolean | string =>
  !value || !!value.match(patternNoComma) || $t(m.noCommaAllowed);

export const isValidOtp = (value: string): boolean | string => {
  if (!value) {
    return false;
  }
  return !!value.match(/^\d{6}$/) || $t(m.invalidOtp);
};

export const isValidLength =
  (length: number) =>
  (value: string): boolean | string => {
    const count = countString(value);
    return (
      count <= length || $t(m.notLongerThan, 1, { length: length.toString() })
    );
  };
