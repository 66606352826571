import { render, staticRenderFns } from "./CreateByMaterialsForm.vue?vue&type=template&id=003f1acd&scoped=true"
import script from "./CreateByMaterialsForm.vue?vue&type=script&setup=true&lang=ts"
export * from "./CreateByMaterialsForm.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "003f1acd",
  null
  
)

export default component.exports