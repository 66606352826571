import { computed } from 'vue';
import { store } from '@/store/index';
import { readUserProfile } from '@/store/main/getters';
import { RoleId } from '@/lib/enum';
import { Department } from '@/interfaces/model/general/department';
import { defineMessages } from '@/lib/i18n';
import sharedMessages from '@/lib/shared-messages';

const m = defineMessages({
  view: '閲覧',
  editOwnDepartment: '編集(自部署)',
  editOtherDepartment: '編集(他部署)',
  upload: 'アップロード',
  requestOwnDepartment: '再データ化依頼(自部署)',
  requestOtherDepartment: '再データ化依頼(他部署)',
  download: 'ダウンロード',
  companyEdit: '会社編集',
  sds: 'SDS',
  createSimple: '簡易作成',
  user: 'ユーザー',
  request: 'リクエスト',
});

const PERMISSIONS = {
  ALL_PRIVILEGES: 'ALL_PRIVILEGES',
  ALLOWED_IP_ADDRESS_CREATE: 'ALLOWED_IP_ADDRESS_CREATE',
  ALLOWED_IP_ADDRESS_DELETE: 'ALLOWED_IP_ADDRESS_DELETE',
  COMPANY_EDIT: 'COMPANY_EDIT',
  COMPANY_DELETE: 'COMPANY_DELETE',
  SDS_UPLOAD: 'SDS_UPLOAD',
  SDS_READ: 'SDS_READ',
  SDS_EDIT_OWN_DEPARTMENT: 'SDS_EDIT_OWN_DEPARTMENT',
  SDS_EDIT_OTHER_DEPARTMENT: 'SDS_EDIT_OTHER_DEPARTMENT',
  SDS_DELETE: 'SDS_DELETE',
  SDS_EXPORT: 'SDS_EXPORT',
  SDS_REQUEST_OWN_DEPARTMENT: 'SDS_REQUEST_OWN_DEPARTMENT',
  SDS_REQUEST_OTHER_DEPARTMENT: 'SDS_REQUEST_OTHER_DEPARTMENT',
  CHECK_LIST_READ: 'CHECK_LIST_READ',
  CHECK_LIST_REQUEST: 'CHECK_LIST_REQUEST',
  CHECK_LIST_EXPORT: 'CHECK_LIST_EXPORT',
  CHECK_LIST_CREATE: 'CHECK_LIST_CREATE',
  CHECK_LIST_DELETE: 'CHECK_LIST_DELETE',
  CHECK_LIST_EDIT: 'CHECK_LIST_EDIT',
  CREATE_SIMPLE_DOWNLOAD: 'CREATE_SIMPLE_DOWNLOAD',
  TAG_CREATE: 'TAG_CREATE',
  TAG_EDIT: 'TAG_EDIT',
  TAG_DELETE: 'TAG_DELETE',
  DEPARTMENT_CREATE: 'DEPARTMENT_CREATE',
  DEPARTMENT_EDIT: 'DEPARTMENT_EDIT',
  DEPARTMENT_DELETE: 'DEPARTMENT_DELETE',
  USER_EDIT: 'USER_EDIT',
  USER_INVITE: 'USER_INVITE',
  USER_DELETE: 'USER_DELETE',
  USER_READ_2FA_INFO: 'USER_READ_2FA_INFO',
} as const;
export type Permission = (typeof PERMISSIONS)[keyof typeof PERMISSIONS];

const ROLE_PERMISSIONS_DESCRIPTION = {
  [RoleId.USER_VIEWER.value]: [
    {
      feature: m.sds,
      actions: [m.view],
    },
    {
      feature: sharedMessages.checklist,
      actions: [m.view],
    },
  ],
  [RoleId.USER_DOWNLOADABLE_VIEWER.value]: [
    {
      feature: m.sds,
      actions: [m.view, sharedMessages.export],
    },
    {
      feature: sharedMessages.checklist,
      actions: [m.view, sharedMessages.export],
    },
    {
      feature: 'CREATE SIMPLE',
      actions: [m.download],
    },
  ],
  [RoleId.USER_EDITOR.value]: [
    {
      feature: m.sds,
      actions: [
        m.view,
        m.editOwnDepartment,
        sharedMessages.delete,
        m.upload,
        sharedMessages.export,
        m.requestOwnDepartment,
      ],
    },
    {
      feature: sharedMessages.checklist,
      actions: [m.view, sharedMessages.export],
    },
    {
      feature: m.createSimple,
      actions: [m.download],
    },
    {
      feature: sharedMessages.tag,
      actions: [
        sharedMessages.create,
        sharedMessages.edit,
        sharedMessages.delete,
      ],
    },
  ],
  [RoleId.USER_ADMIN.value]: [
    {
      feature: m.sds,
      actions: [
        m.view,
        m.editOwnDepartment,
        m.editOtherDepartment,
        sharedMessages.delete,
        m.upload,
        sharedMessages.export,
        m.requestOwnDepartment,
        m.requestOtherDepartment,
      ],
    },
    {
      feature: sharedMessages.checklist,
      actions: [m.view, sharedMessages.edit, sharedMessages.export, m.request],
    },
    {
      feature: m.createSimple,
      actions: [m.download],
    },
    {
      feature: sharedMessages.tag,
      actions: [
        sharedMessages.create,
        sharedMessages.edit,
        sharedMessages.delete,
      ],
    },
    {
      feature: sharedMessages.whitelistIpAddress,
      actions: [sharedMessages.create, sharedMessages.delete],
    },
    {
      feature: sharedMessages.department,
      actions: [
        sharedMessages.create,
        sharedMessages.edit,
        sharedMessages.delete,
      ],
    },
    {
      feature: m.user,
      actions: [
        sharedMessages.invite,
        sharedMessages.edit,
        sharedMessages.delete,
      ],
    },
    {
      feature: sharedMessages.company,
      actions: [m.companyEdit],
    },
  ],
} as const;

export const usePermissionComposable = () => {
  const userPermissions = computed(() => {
    return readUserProfile(store).role.permissions.map((p) => p.id);
  });

  const hasPermission = (required: Permission | Permission[]) => {
    if (userPermissions.value.includes(PERMISSIONS.ALL_PRIVILEGES)) {
      return true;
    }

    if (Array.isArray(required)) {
      return required.every((p) => userPermissions.value.includes(p));
    }
    return userPermissions.value.includes(required);
  };

  const hasSdsEditPermission = (sdsDepartments: Department[]) => {
    const userDepartmentIds = readUserProfile(store).departments.map(
      (d) => d.uuid
    );
    const sdsDepartmentIds = sdsDepartments.map((d) => d.uuid);
    const hasOwnDepartment = sdsDepartmentIds.some((d) =>
      userDepartmentIds.includes(d)
    );

    if (
      !hasPermission(PERMISSIONS.SDS_EDIT_OWN_DEPARTMENT) &&
      !hasPermission(PERMISSIONS.SDS_EDIT_OTHER_DEPARTMENT)
    ) {
      return false;
    }
    if (
      hasOwnDepartment &&
      hasPermission(PERMISSIONS.SDS_EDIT_OWN_DEPARTMENT)
    ) {
      return true;
    }
    if (
      !hasOwnDepartment &&
      hasPermission(PERMISSIONS.SDS_EDIT_OTHER_DEPARTMENT)
    ) {
      return true;
    }
    if (sdsDepartments.length === 0) {
      return true;
    }
    return false;
  };

  const hasSdsRequestPermission = (sdsDepartments: Department[]) => {
    const userDepartmentIds = readUserProfile(store).departments.map(
      (d) => d.uuid
    );
    const sdsDepartmentIds = sdsDepartments.map((d) => d.uuid);
    const hasOwnDepartment = sdsDepartmentIds.some((d) =>
      userDepartmentIds.includes(d)
    );

    if (
      !hasPermission(PERMISSIONS.SDS_REQUEST_OWN_DEPARTMENT) &&
      !hasPermission(PERMISSIONS.SDS_REQUEST_OTHER_DEPARTMENT)
    ) {
      return false;
    }
    if (
      hasOwnDepartment &&
      hasPermission(PERMISSIONS.SDS_REQUEST_OWN_DEPARTMENT)
    ) {
      return true;
    }
    if (
      !hasOwnDepartment &&
      hasPermission(PERMISSIONS.SDS_REQUEST_OTHER_DEPARTMENT)
    ) {
      return true;
    }
    if (sdsDepartments.length === 0) {
      return true;
    }
    return false;
  };

  return {
    hasPermission,
    hasSdsEditPermission,
    hasSdsRequestPermission,
    PERMISSIONS,
    ROLE_PERMISSIONS_DESCRIPTION,
  };
};
