import { LawId } from './LawId';

export class RegulationId {
  private constructor(
    readonly value: number,
    readonly text: string,
    readonly textJp: string,
    readonly textEn: string,
    readonly textCheckListRequest: string,
    readonly lawId: number
  ) {
    RegulationId._values.push(this);
  }

  private static readonly _values: RegulationId[] = [];

  static readonly DEFAULT = new RegulationId(0, '', '', '', '', 0);
  static readonly REGULATION1_LAW_ENCS_0000 = new RegulationId(
    200000000,
    '第一種特定化学物質 ; Class I Specified Chemical Substances',
    '第一種特定化学物質',
    'Class I Specified Chemical Substances',
    '第一種特定化学物質',
    100000000
  );
  static readonly REGULATION1_LAW_ENCS_0001 = new RegulationId(
    200000001,
    '第二種特定化学物質 ; Class II Specified Chemical Substances',
    '第二種特定化学物質',
    'Class II Specified Chemical Substances',
    '第二種特定化学物質',
    100000000
  );
  static readonly REGULATION1_LAW_ENCS_0002 = new RegulationId(
    200000002,
    '監視化学物質 ; Monitoring Chemical Substances',
    '監視化学物質',
    'Monitoring Chemical Substances',
    '監視化学物質',
    100000000
  );
  static readonly REGULATION1_LAW_ENCS_0003 = new RegulationId(
    200000003,
    '優先評価化学物質 ; Priority Assessment Chemical Substances',
    '優先評価化学物質',
    'Priority Assessment Chemical Substances',
    '優先評価化学物質',
    100000000
  );
  static readonly REGULATION1_LAW_ENCS_0004 = new RegulationId(
    200000004,
    '（取消）優先評価化学物質 ; Canceled Priority Assessment Chemical Substances',
    '（取消）優先評価化学物質',
    'Canceled Priority Assessment Chemical Substances',
    '（取消）優先評価化学物質',
    100000000
  );
  static readonly REGULATION1_LAW_ENCS_0005 = new RegulationId(
    200000005,
    '新規公示化学物質 ; Newly Announced Chemical Substances',
    '新規公示化学物質',
    'Newly Announced Chemical Substances',
    '新規公示化学物質',
    100000000
  );
  static readonly REGULATION1_LAW_ENCS_0006 = new RegulationId(
    200000006,
    '新規公示化学物質（2011年4月1日以降届出） ; Newly Announced Chemical Substances (notified on and after April 1, 2011)',
    '新規公示化学物質（2011年4月1日以降届出）',
    'Newly Announced Chemical Substances (notified on and after April 1, 2011)',
    '新規公示化学物質（2011年4月1日以降届出）',
    100000000
  );
  static readonly REGULATION1_LAW_ENCS_0007 = new RegulationId(
    200000007,
    '新規公示化学物質（2011年3月31日以前届出） ; Newly Announced Chemical Substances (notified by March 31, 2011)',
    '新規公示化学物質（2011年3月31日以前届出）',
    'Newly Announced Chemical Substances (notified by March 31, 2011)',
    '新規公示化学物質（2011年3月31日以前届出）',
    100000000
  );
  static readonly REGULATION1_LAW_ENCS_0008 = new RegulationId(
    200000008,
    '既存化学物質 ; Existing Chemical Substances',
    '既存化学物質',
    'Existing Chemical Substances',
    '既存化学物質',
    100000000
  );
  static readonly REGULATION1_LAW_ENCS_0009 = new RegulationId(
    200000009,
    '旧第二種監視化学物質 ; Type II Monitoring Chemical Substances (before amendment)',
    '旧第二種監視化学物質',
    'Type II Monitoring Chemical Substances (before amendment)',
    '旧第二種監視化学物質',
    100000000
  );
  static readonly REGULATION1_LAW_ENCS_0010 = new RegulationId(
    200000010,
    '旧第三種監視化学物質 ; Type III Monitoring Chemical Substances (before amendment)',
    '旧第三種監視化学物質',
    'Type III Monitoring Chemical Substances (before amendment)',
    '旧第三種監視化学物質',
    100000000
  );
  static readonly REGULATION1_LAW_ENCS_0011 = new RegulationId(
    200000011,
    '製造輸入量の届出を要しない物質（届出不要物質） ; Chemical Substances exempted from notification of manufacturing or import amount',
    '製造輸入量の届出を要しない物質（届出不要物質）',
    'Chemical Substances exempted from notification of manufacturing or import amount',
    '製造輸入量の届出を要しない物質（届出不要物質）',
    100000000
  );
  static readonly REGULATION1_LAW_ENCS_0012 = new RegulationId(
    200000012,
    '一般化学物質 ; ',
    '一般化学物質',
    '',
    '一般化学物質',
    100000000
  );
  static readonly REGULATION1_LAW_PRTR_0000 = new RegulationId(
    200010000,
    '指定化学物質',
    '指定化学物質',
    '',
    '指定化学物質',
    100010000
  );
  static readonly REGULATION1_LAW_PRTR_0001 = new RegulationId(
    200010001,
    '第一種指定化学物質 ; Class I',
    '第一種指定化学物質',
    'Class I',
    '第一種指定化学物質',
    100010000
  );
  static readonly REGULATION1_LAW_PRTR_0002 = new RegulationId(
    200010002,
    '第二種指定化学物質 ; Class II',
    '第二種指定化学物質',
    'Class II',
    '第二種指定化学物質',
    100010000
  );
  static readonly REGULATION1_LAW_PRTR_0003 = new RegulationId(
    200010003,
    '特定第一種指定化学物質 ; Specified Class I',
    '特定第一種指定化学物質',
    'Specified Class I',
    '特定第一種指定化学物質',
    100010000
  );
  static readonly REGULATION1_LAW_PRTR_0004 = new RegulationId(
    200010004,
    '2023年3月31日まで　第一種指定化学物質 ; Class I (notified by March 31, 2023)',
    '2023年3月31日まで　第一種指定化学物質',
    'Class I (notified by March 31, 2023)',
    '2023年3月31日まで　第一種指定化学物質',
    100010000
  );
  static readonly REGULATION1_LAW_PRTR_0005 = new RegulationId(
    200010005,
    '2023年3月31日まで　第二種指定化学物質 ; Class II (notified by March 31, 2023)',
    '2023年3月31日まで　第二種指定化学物質',
    'Class II (notified by March 31, 2023)',
    '2023年3月31日まで　第二種指定化学物質',
    100010000
  );

  static readonly REGULATION1_LAW_PRTR_0006 = new RegulationId(
    200010006,
    '2023年3月31日まで　特定第一種指定化学物質 ; Specified Class I (notified by March 31, 2023)',
    '2023年3月31日まで　特定第一種指定化学物質',
    'Specified Class I (notified by March 31, 2023)',
    '2023年3月31日まで　特定第一種指定化学物質',
    100010000
  );

  static readonly REGULATION1_LAW_PRTR_0007 = new RegulationId(
    200010007,
    '2023年4月1日から　第一種指定化学物質 ; Class I (notified on and after April 1, 2023)',
    '2023年4月1日から　第一種指定化学物質',
    'Class I (notified on and after April 1, 2023)',
    '2023年4月1日から　第一種指定化学物質',
    100010000
  );

  static readonly REGULATION1_LAW_PRTR_0008 = new RegulationId(
    200010008,
    '2023年4月1日から　第二種指定化学物質 ; Class II (notified on and after April 1, 2023)',
    '2023年4月1日から　第二種指定化学物質',
    'Class II (notified on and after April 1, 2023)',
    '2023年4月1日から　第二種指定化学物質',
    100010000
  );

  static readonly REGULATION1_LAW_PRTR_0009 = new RegulationId(
    200010009,
    '2023年4月1日から　特定第一種指定化学物質 ; Specified Class I (notified on and after April 1, 2023)',
    '2023年4月1日から　特定第一種指定化学物質',
    'Specified Class I (notified on and after April 1, 2023)',
    '2023年4月1日から　特定第一種指定化学物質',
    100010000
  );

  static readonly REGULATION1_LAW_PRTR_0010 = new RegulationId(
    200010010,
    '2023年3月31日まで',
    '2023年3月31日まで',
    '2023年3月31日まで',
    '2023年3月31日まで',
    100010000
  );

  static readonly REGULATION1_LAW_PRTR_0011 = new RegulationId(
    200010011,
    '2023年4月1日から',
    '2023年4月1日から',
    '2023年4月1日から',
    '2023年4月1日から',
    100010000
  );

  static readonly REGULATION1_LAW_ISHL_0000 = new RegulationId(
    200020000,
    '既存化学物質（名称公表化学物質、新規名称公表化学物質） ; Existing Chemical Substances (including Newly Announced Chemical Substances)',
    '既存化学物質（名称公表化学物質、新規名称公表化学物質）',
    'Existing Chemical Substances (including Newly Announced Chemical Substances)',
    '既存化学物質（名称公表化学物質、新規名称公表化学物質）',
    100020000
  );

  static readonly REGULATION1_LAW_ISHL_0001 = new RegulationId(
    200020001,
    '新規名称公表化学物質 ; Newly Announced Chemical Substances',
    '新規名称公表化学物質',
    'Newly Announced Chemical Substances',
    '新規名称公表化学物質',
    100020000
  );

  static readonly REGULATION1_LAW_ISHL_0002 = new RegulationId(
    200020002,
    '製造等が禁止される有害物等 ; Chemical Substances Prohibited to Manufacturing, etc',
    '製造等が禁止される有害物等',
    'Chemical Substances Prohibited to Manufacturing, etc',
    '製造等が禁止される有害物等',
    100020000
  );

  static readonly REGULATION1_LAW_ISHL_0003 = new RegulationId(
    200020003,
    '製造の許可を受けるべき有害物 ; Chemical Substances Requiring Permission for Manufacture, etc',
    '製造の許可を受けるべき有害物',
    'Chemical Substances Requiring Permission for Manufacture, etc',
    '製造の許可を受けるべき有害物',
    100020000
  );

  static readonly REGULATION1_LAW_ISHL_0004 = new RegulationId(
    200020004,
    '表示又は通知対象物質（名称等を表示し、又は通知すべき危険物及び有害物）_第57条・施行令第18条(別表第9) ; Chemical Substances Requiring Labeling and Delivery of Documents, etc.',
    '表示又は通知対象物質（名称等を表示し、又は通知すべき危険物及び有害物）_第57条・施行令第18条(別表第9)',
    'Chemical Substances Requiring Labeling and Delivery of Documents, etc.',
    '表示又は通知対象物質（2026年（令和8年）4月1日追加分・2025年（令和8年）4月1日追加分を含む）',
    100020000
  );

  static readonly REGULATION1_LAW_ISHL_0005 = new RegulationId(
    200020005,
    '第 28 条第３項 健康障害を防止するための指針（がん原性に係る指針対象物質） ; Guidelines for Carcinogenic Substances',
    '第 28 条第３項 健康障害を防止するための指針（がん原性に係る指針対象物質）',
    'Guidelines for Carcinogenic Substances',
    '第 28 条第３項 健康障害を防止するための指針（がん原性に係る指針対象物質）',
    100020000
  );

  static readonly REGULATION1_LAW_ISHL_0006 = new RegulationId(
    200020006,
    '危険物 ; Dangerous',
    '危険物',
    '危険物 ; Dangerous',
    '危険物 ; Dangerous',
    100020000
  );

  static readonly REGULATION1_LAW_ISHL_0007 = new RegulationId(
    200020007,
    '特定化学物質障害予防規則（特定化学物質等・特化則） ; Specified Chemical Substances',
    '特定化学物質障害予防規則（特定化学物質等・特化則）',
    'Specified Chemical Substances',
    '特定化学物質障害予防規則（特定化学物質等・特化則）',
    100020000
  );

  static readonly REGULATION1_LAW_ISHL_0008 = new RegulationId(
    200020008,
    '有機溶剤中毒予防規則（有機溶剤等・有機則） ; Ordinance on Prevention of Organic Solvent Poisoning',
    '有機溶剤中毒予防規則（有機溶剤等・有機則）',
    'Ordinance on Prevention of Organic Solvent Poisoning',
    '有機溶剤中毒予防規則（有機溶剤等・有機則）',
    100020000
  );

  static readonly REGULATION1_LAW_ISHL_0009 = new RegulationId(
    200020009,
    '強い変異原性が認められた化学物質（変異原性:57条の4） ; Strong Mutagenic Chemical Substances',
    '強い変異原性が認められた化学物質（変異原性:57条の4）',
    'Strong Mutagenic Chemical Substances',
    '強い変異原性が認められた化学物質（変異原性:57条の4）',
    100020000
  );

  static readonly REGULATION1_LAW_ISHL_0010 = new RegulationId(
    200020010,
    '変異原性が認められた化学物質（変異原性:57条の5） ; Mutagenic Chemical Substances',
    '変異原性が認められた化学物質（変異原性:57条の5）',
    'Mutagenic Chemical Substances',
    '変異原性が認められた化学物質（変異原性:57条の5）',
    100020000
  );

  static readonly REGULATION1_LAW_ISHL_0011 = new RegulationId(
    200020011,
    '変異原性が認められた化学物質（変異原性:57条の5）既存化学物質 ; Mutagenic Chemical Substances',
    '変異原性が認められた化学物質（変異原性:57条の5）既存化学物質',
    'Mutagenic Chemical Substances',
    '変異原性が認められた化学物質（変異原性:57条の5）既存化学物質',
    100020000
  );

  static readonly REGULATION1_LAW_ISHL_0012 = new RegulationId(
    200020012,
    '変異原性が認められた化学物質（変異原性:57条の5）新規届出化学物質 ; Mutagenic Chemical Substances',
    '変異原性が認められた化学物質（変異原性:57条の5）新規届出化学物質',
    'Mutagenic Chemical Substances',
    '変異原性が認められた化学物質（変異原性:57条の5）新規届出化学物質',
    100020000
  );

  static readonly REGULATION1_LAW_ISHL_0013 = new RegulationId(
    200020013,
    '鉛等（鉛中毒予防規則） ; Ordinance on Prevention of Lead Poisoning',
    '鉛等（鉛中毒予防規則）',
    'Ordinance on Prevention of Lead Poisoning',
    '鉛等（鉛中毒予防規則）',
    100020000
  );

  static readonly REGULATION1_LAW_ISHL_0014 = new RegulationId(
    200020014,
    '四アルキル鉛等（四アルキル鉛中毒予防規則） ; Ordinance on Prevention of Tetraalkyl Lead Poisoning, etc',
    '四アルキル鉛等（四アルキル鉛中毒予防規則）',
    'Ordinance on Prevention of Tetraalkyl Lead Poisoning, etc',
    '四アルキル鉛等（四アルキル鉛中毒予防規則）',
    100020000
  );

  static readonly REGULATION1_LAW_ISHL_0015 = new RegulationId(
    200020015,
    '作業環境評価基準で定める管理濃度 ; Administrative Control Levels of Working Environment Evaluation Standards',
    '作業環境評価基準で定める管理濃度',
    'Administrative Control Levels of Working Environment Evaluation Standards',
    '作業環境評価基準で定める管理濃度',
    100020000
  );

  static readonly REGULATION1_LAW_ISHL_0016 = new RegulationId(
    200020016,
    '粉塵障害防止規則(粉塵則・粉じん障害防止規則・粉じん則) ; Ordinance on Prevention of Hazards due to Dust',
    '粉塵障害防止規則(粉塵則・粉じん障害防止規則・粉じん則)',
    'Ordinance on Prevention of Hazards due to Dust',
    '粉塵障害防止規則(粉塵則・粉じん障害防止規則・粉じん則)',
    100020000
  );

  static readonly REGULATION1_LAW_ISHL_0017 = new RegulationId(
    200020017,
    '腐食性液体 ; Corrosive Liquids',
    '腐食性液体',
    'Corrosive Liquids',
    '腐食性液体',
    100020000
  );

  static readonly REGULATION1_LAW_ISHL_0018 = new RegulationId(
    200020018,
    '健康診断対象物質 ; Substances Subject to Medical Examination',
    '健康診断対象物質',
    'Substances Subject to Medical Examination',
    '健康診断対象物質',
    100020000
  );

  static readonly REGULATION1_LAW_ISHL_0019 = new RegulationId(
    200020019,
    '酸素欠乏症防止規則 ; Ordinance on Prevention of Anoxia',
    '酸素欠乏症防止規則',
    'Ordinance on Prevention of Anoxia',
    '酸素欠乏症防止規則',
    100020000
  );

  static readonly REGULATION1_LAW_ISHL_0020 = new RegulationId(
    200020020,
    '事務所衛生基準規則 ; Ordinance on Health Standards in the Office',
    '事務所衛生基準規則',
    'Ordinance on Health Standards in the Office',
    '事務所衛生基準規則',
    100020000
  );

  static readonly REGULATION1_LAW_ISHL_0021 = new RegulationId(
    200020021,
    'リスクアセスメント対象物質 ; Substances requiring Risk Assessment',
    'リスクアセスメント対象物質',
    'Substances requiring Risk Assessment',
    'リスクアセスメント対象物質',
    100020000
  );

  static readonly REGULATION1_LAW_ISHL_0022 = new RegulationId(
    200020022,
    '危険有害化学物質等(規則第24条の14)',
    '危険有害化学物質等(規則第24条の14)',
    '危険有害化学物質等(規則第24条の14)',
    '危険有害化学物質等(規則第24条の14)',
    100020000
  );

  static readonly REGULATION1_LAW_ISHL_0023 = new RegulationId(
    200020023,
    '特定危険有害化学物質等(規則第24条の15)',
    '特定危険有害化学物質等(規則第24条の15)',
    '特定危険有害化学物質等(規則第24条の15)',
    '特定危険有害化学物質等(規則第24条の15)',
    100020000
  );

  // 200020024 was deleted and moved to 300020013

  static readonly REGULATION1_LAW_ISHL_0025 = new RegulationId(
    200020025,
    'がん原性物質（作業記録等の30年保存対象物質） ; Carcinogenicity',
    'がん原性物質（作業記録等の30年保存対象物質）',
    'Carcinogenicity',
    'がん原性物質（作業記録等の30年保存対象物質）',
    100020000
  );

  static readonly REGULATION1_LAW_ISHL_0026 = new RegulationId(
    200020026,
    '名称公表化学物質 ; Existing Chemical Substances',
    '名称公表化学物質',
    'Existing Chemical Substances',
    '名称公表化学物質',
    100020000
  );

  static readonly REGULATION1_LAW_ISHL_0027 = new RegulationId(
    200020027,
    'がん原性に係る指針対象物質（健康障害を防止するための指針） ; Guidelines for Carcinogenic Substances',
    'がん原性に係る指針対象物質（健康障害を防止するための指針）',
    'Guidelines for Carcinogenic Substances',
    'がん原性に係る指針対象物質（健康障害を防止するための指針）',
    100020000
  );

  static readonly REGULATION1_LAW_ISHL_0028 = new RegulationId(
    200020028,
    '特定化学物質等（特化則） ; Specified Chemical Substances',
    '特定化学物質等（特化則）',
    'Specified Chemical Substances',
    '特定化学物質等（特化則）',
    100020000
  );

  static readonly REGULATION1_LAW_ISHL_0029 = new RegulationId(
    200020029,
    '第一類特定化学物質 ; Class I',
    '第一類特定化学物質',
    'Class I',
    '第一類特定化学物質',
    100020000
  );

  static readonly REGULATION1_LAW_ISHL_0030 = new RegulationId(
    200020030,
    '第二類特定化学物質 ; Class II',
    '第二類特定化学物質',
    'Class II',
    '第二類特定化学物質',
    100020000
  );

  static readonly REGULATION1_LAW_ISHL_0031 = new RegulationId(
    200020031,
    '第三類特定化学物質 ; Class III',
    '第三類特定化学物質',
    'Class III',
    '第三類特定化学物質',
    100020000
  );

  static readonly REGULATION1_LAW_ISHL_0032 = new RegulationId(
    200020032,
    '皮膚等障害化学物質等(規則 第594条の2 第1項)',
    '皮膚等障害化学物質等(規則 第594条の2 第1項)',
    '皮膚等障害化学物質等(規則 第594条の2 第1項)',
    '皮膚等障害化学物質等(規則 第594条の2 第1項)',
    100020000
  );

  static readonly REGULATION1_LAW_ISHL_0033 = new RegulationId(
    200020033,
    '（2024年 4月以降対象予定）表示又は通知対象物質（名称等を表示し、又は通知すべき危険物及び有害物）_第57条・施行令第18条(別表第9) ; Chemical Substances Requiring Labeling and Delivery of Documents, etc',
    '（2024年 4月以降対象予定）表示又は通知対象物質（名称等を表示し、又は通知すべき危険物及び有害物）_第57条・施行令第18条(別表第9)',
    'Chemical Substances Requiring Labeling and Delivery of Documents, etc',
    '（2024年 4月以降対象予定）表示又は通知対象物質（名称等を表示し、又は通知すべき危険物及び有害物）_第57条・施行令第18条(別表第9)',
    100020000
  );

  static readonly REGULATION1_LAW_ISHL_0034 = new RegulationId(
    200020034,
    '（2025年 4月以降対象予定）表示又は通知対象物質（名称等を表示し、又は通知すべき危険物及び有害物）_第57条・施行令第18条(別表第9) ; Chemical Substances Requiring Labeling and Delivery of Documents, etc',
    '（2025年 4月以降対象予定）表示又は通知対象物質（名称等を表示し、又は通知すべき危険物及び有害物）_第57条・施行令第18条(別表第9)',
    'Chemical Substances Requiring Labeling and Delivery of Documents, etc',
    '（2025年 4月以降対象予定）表示又は通知対象物質（名称等を表示し、又は通知すべき危険物及び有害物）_第57条・施行令第18条(別表第9)',
    100020000
  );

  static readonly REGULATION1_LAW_ISHL_0035 = new RegulationId(
    200020035,
    '（2026年 4月以降対象予定）表示又は通知対象物質（名称等を表示し、又は通知すべき危険物及び有害物）_第57条・施行令第18条(別表第9) ; Chemical Substances Requiring Labeling and Delivery of Documents, etc',
    '（2026年 4月以降対象予定）表示又は通知対象物質（名称等を表示し、又は通知すべき危険物及び有害物）_第57条・施行令第18条(別表第9)',
    'Chemical Substances Requiring Labeling and Delivery of Documents, etc',
    '（2026年 4月以降対象予定）表示又は通知対象物質（名称等を表示し、又は通知すべき危険物及び有害物）_第57条・施行令第18条(別表第9)',
    100020000
  );

  static readonly REGULATION1_LAW_ISHL_0036 = new RegulationId(
    200020036,
    'エポキシ樹脂の硬化剤による健康障害防止',
    'エポキシ樹脂の硬化剤による健康障害防止',
    'エポキシ樹脂の硬化剤による健康障害防止',
    'エポキシ樹脂の硬化剤による健康障害防止',
    100020000
  );

  static readonly REGULATION1_LAW_ISHL_0037 = new RegulationId(
    200020037,
    '濃度基準値設定物質（化学物質による健康障害防止のための濃度の基準_労働安全衛生規則第577条の2）',
    '濃度基準値設定物質（化学物質による健康障害防止のための濃度の基準_労働安全衛生規則第577条の2）',
    '濃度基準値設定物質（化学物質による健康障害防止のための濃度の基準_労働安全衛生規則第577条の2）',
    '濃度基準値設定物質（2025年10月施行分を含む）',
    100020000
  );

  static readonly REGULATION1_LAW_ISHL_0038 = new RegulationId(
    200020038,
    '労働基準局長通達基発第312号_変異原性が認められた化学物質による健康障害を防止するための指針',
    '労働基準局長通達基発第312号3の別添1_変異原性が認められた化学物質による健康障害を防止するための指針',
    '労働基準局長通達基発第312号3の別添1_変異原性が認められた化学物質による健康障害を防止するための指針',
    '労働基準局長通達基発第312号_変異原性が認められた化学物質による健康障害を防止するための指針',
    100020000
  );

  static readonly REGULATION1_LAW_ISHL_0039 = new RegulationId(
    200020039,
    '労働基準局長通達基発第348号_変異原性化学物質',
    '労働基準局長通達基発第348号の2_変異原性化学物質',
    '労働基準局長通達基発第348号の2_変異原性化学物質',
    '労働基準局長通達基発第348号_変異原性化学物質',
    100020000
  );

  static readonly REGULATION1_LAW_ISHL_0040 = new RegulationId(
    200020040,
    '労働基準局長通達基発第341号_変異原性が認められた化学物質',
    '労働基準局長通達基発第341号_変異原性が認められた化学物質',
    '労働基準局長通達基発第341号_変異原性が認められた化学物質',
    '労働基準局長通達基発第341号_変異原性が認められた化学物質',
    100020000
  );

  // 200020041 was deleted and moved to 300020014

  static readonly REGULATION1_LAW_ISHL_0042 = new RegulationId(
    200020042,
    '事業者が行うべき調査等（第57条の3第1項）',
    '事業者が行うべき調査等（第57条の3第1項）',
    '事業者が行うべき調査等（第57条の3第1項）',
    '事業者が行うべき調査等（第57条の3第1項）',
    100020000
  );

  static readonly REGULATION1_LAW_ISHL_0043 = new RegulationId(
    200020043,
    '石綿障害予防規則;Ordinance on Prevention of Health Impairment due to Asbestos',
    '石綿障害予防規則',
    'Ordinance on Prevention of Health Impairment due to Asbestos',
    '石綿障害予防規則',
    100020000
  );

  static readonly REGULATION1_LAW_POISONOUS_AND_DELETERIOUS_SUBSTANCES_CONTROL_ACT_0000 =
    new RegulationId(
      200030000,
      '有機シアン化合物から除かれるもの ; Substances Excluded from Organic Cyanide Compounds',
      '有機シアン化合物から除かれるもの',
      'Substances Excluded from Organic Cyanide Compounds',
      '有機シアン化合物から除かれるもの',
      100030000
    );

  static readonly REGULATION1_LAW_POISONOUS_AND_DELETERIOUS_SUBSTANCES_CONTROL_ACT_0001 =
    new RegulationId(
      200030001,
      '毒物（法令） ; Poisonous Substances',
      '毒物（法令）',
      'Poisonous Substances',
      '毒物（法令）',
      100030000
    );

  static readonly REGULATION1_LAW_POISONOUS_AND_DELETERIOUS_SUBSTANCES_CONTROL_ACT_0002 =
    new RegulationId(
      200030002,
      '毒物（政令） ; Poisonous Substances',
      '毒物（政令）',
      'Poisonous Substances',
      '毒物（政令）',
      100030000
    );

  static readonly REGULATION1_LAW_POISONOUS_AND_DELETERIOUS_SUBSTANCES_CONTROL_ACT_0003 =
    new RegulationId(
      200030003,
      '劇物（法令） ; Deleterious Substances',
      '劇物（法令）',
      'Deleterious Substances',
      '劇物（法令）',
      100030000
    );

  static readonly REGULATION1_LAW_POISONOUS_AND_DELETERIOUS_SUBSTANCES_CONTROL_ACT_0004 =
    new RegulationId(
      200030004,
      '劇物（政令） ; Deleterious Substances',
      '劇物（政令）',
      'Deleterious Substances',
      '劇物（政令）',
      100030000
    );

  static readonly REGULATION1_LAW_POISONOUS_AND_DELETERIOUS_SUBSTANCES_CONTROL_ACT_0005 =
    new RegulationId(
      200030005,
      '特定毒物（法令） ; Specified Poisonous Substances',
      '特定毒物（法令）',
      'Specified Poisonous Substances',
      '特定毒物（法令）',
      100030000
    );

  static readonly REGULATION1_LAW_POISONOUS_AND_DELETERIOUS_SUBSTANCES_CONTROL_ACT_0006 =
    new RegulationId(
      200030006,
      '特定毒物（政令） ; Specified Poisonous Substances',
      '特定毒物（政令）',
      'Specified Poisonous Substances',
      '特定毒物（政令）',
      100030000
    );

  static readonly REGULATION1_LAW_POISONOUS_AND_DELETERIOUS_SUBSTANCES_CONTROL_ACT_0007 =
    new RegulationId(
      200030007,
      '法律・毒物／政令・毒物 ; Poisonous Substances',
      '法律・毒物／政令・毒物',
      'Poisonous Substances',
      '法律・毒物／政令・毒物',
      100030000
    );

  static readonly REGULATION1_LAW_POISONOUS_AND_DELETERIOUS_SUBSTANCES_CONTROL_ACT_0008 =
    new RegulationId(
      200030008,
      '法律・劇物／政令・劇物 ; Deleterious Substances',
      '法律・劇物／政令・劇物',
      'Deleterious Substances',
      '法律・劇物／政令・劇物',
      100030000
    );

  static readonly REGULATION1_LAW_POISONOUS_AND_DELETERIOUS_SUBSTANCES_CONTROL_ACT_0009 =
    new RegulationId(
      200030009,
      '法律・特定毒物／政令・特定毒物 ; Specified Poisonous Substances',
      '法律・特定毒物／政令・特定毒物',
      'Specified Poisonous Substances',
      '法律・特定毒物／政令・特定毒物',
      100030000
    );

  static readonly REGULATION1_LAW_POISONOUS_AND_DELETERIOUS_SUBSTANCES_CONTROL_ACT_0010 =
    new RegulationId(
      200030010,
      '毒物 ; Poisonous Substances',
      '毒物',
      'Poisonous Substances',
      '毒物',
      100030000
    );

  static readonly REGULATION1_LAW_POISONOUS_AND_DELETERIOUS_SUBSTANCES_CONTROL_ACT_0011 =
    new RegulationId(
      200030011,
      '劇物 ; Deleterious Substances',
      '劇物',
      'Deleterious Substances',
      '劇物',
      100030000
    );

  static readonly REGULATION1_LAW_POISONOUS_AND_DELETERIOUS_SUBSTANCES_CONTROL_ACT_0012 =
    new RegulationId(
      200030012,
      '特定毒物 ; Specified Poisonous Substances',
      '特定毒物',
      'Specified Poisonous Substances',
      '特定毒物',
      100030000
    );

  static readonly REGULATION1_LAW_FIRE_SERVICE_ACT_0000 = new RegulationId(
    200040000,
    '第1類 ; Category1',
    '第1類',
    'Category1',
    '第1類',
    100040000
  );

  static readonly REGULATION1_LAW_FIRE_SERVICE_ACT_0001 = new RegulationId(
    200040001,
    '第2類 ; Category2',
    '第2類',
    'Category2',
    '第2類',
    100040000
  );

  static readonly REGULATION1_LAW_FIRE_SERVICE_ACT_0002 = new RegulationId(
    200040002,
    '第3類 ; Category3',
    '第3類',
    'Category3',
    '第3類',
    100040000
  );

  static readonly REGULATION1_LAW_FIRE_SERVICE_ACT_0003 = new RegulationId(
    200040003,
    '第4類 ; Category4',
    '第4類',
    'Category4',
    '第4類',
    100040000
  );

  static readonly REGULATION1_LAW_FIRE_SERVICE_ACT_0004 = new RegulationId(
    200040004,
    '第5類 ; Category5',
    '第5類',
    'Category5',
    '第5類',
    100040000
  );

  static readonly REGULATION1_LAW_FIRE_SERVICE_ACT_0005 = new RegulationId(
    200040005,
    '第6類 ; Category6',
    '第6類',
    'Category6',
    '第6類',
    100040000
  );

  static readonly REGULATION1_LAW_FIRE_SERVICE_ACT_0007 = new RegulationId(
    200040007,
    '指定可燃物 ; Specified Combustibles',
    '指定可燃物',
    'Specified Combustibles',
    '指定可燃物',
    100040000
  );

  static readonly REGULATION1_LAW_FIRE_SERVICE_ACT_0008 = new RegulationId(
    200040008,
    '貯蔵等の届出を要する物質（法第９条の３・危険物令第１条の１０） ; Article 9-3 Substances requiring notification of storage',
    '貯蔵等の届出を要する物質（法第９条の３・危険物令第１条の１０）',
    'Article 9-3 Substances requiring notification of storage',
    '貯蔵等の届出を要する物質（法第９条の３・危険物令第１条の１０）',
    100040000
  );

  static readonly REGULATION1_LAW_FIRE_SERVICE_ACT_0009 = new RegulationId(
    200040009,
    '火災予防条例 ; Fire Prevention Ordinance',
    '火災予防条例',
    'Fire Prevention Ordinance',
    '火災予防条例',
    100040000
  );

  static readonly REGULATION1_LAW_FIRE_SERVICE_ACT_0010 = new RegulationId(
    200040010,
    '危険等級 I ; Hazard Class 1',
    '危険等級 I',
    'Hazard Class 1',
    '危険等級 I',
    100040000
  );

  static readonly REGULATION1_LAW_FIRE_SERVICE_ACT_0011 = new RegulationId(
    200040011,
    '危険等級 II ; Hazard Class 2',
    '危険等級 II',
    'Hazard Class 2',
    '危険等級 II',
    100040000
  );

  static readonly REGULATION1_LAW_FIRE_SERVICE_ACT_0012 = new RegulationId(
    200040012,
    '危険等級 III ; Hazard Class 3',
    '危険等級 III',
    'Hazard Class 3',
    '危険等級 III',
    100040000
  );

  static readonly REGULATION1_LAW_FIRE_SERVICE_ACT_0014 = new RegulationId(
    200040014,
    '非危険物 ; Non-Hazardous Material',
    '非危険物',
    'Non-Hazardous Material',
    '非危険物',
    100040000
  );

  static readonly REGULATION1_LAW_FOREIGN_EXCHANGE_AND_FOREIGN_TRADE_ACT_0000 =
    new RegulationId(
      200050000,
      '輸出貿易管理令別表第 1 の 16 の項 キャッチオール規制 ; Catch-All Controls (Export Trade Control Order Article 1 Appended table 1)',
      '輸出貿易管理令別表第 1 の 16 の項 キャッチオール規制',
      'Catch-All Controls (Export Trade Control Order Article 1 Appended table 1)',
      '輸出貿易管理令別表第 1 の 16 の項 キャッチオール規制',
      100050000
    );

  static readonly REGULATION1_LAW_FOREIGN_EXCHANGE_AND_FOREIGN_TRADE_ACT_0001 =
    new RegulationId(
      200050001,
      '輸出貿易管理令別表第2 輸出の承認 ; Export Trade Control Appended Table 2 (Export Approval)',
      '輸出貿易管理令別表第2 輸出の承認',
      'Export Trade Control Appended Table 2 (Export Approval)',
      '輸出貿易管理令別表第2 輸出の承認',
      100050000
    );
  static readonly REGULATION1_LAW_FOREIGN_EXCHANGE_AND_FOREIGN_TRADE_ACT_0002 =
    new RegulationId(
      200050002,
      '輸出貿易管理令別表第1の1から15の項 リスト規制 ; Paragraphs 1-15 of Appended Table 1 of the Export Trade Control Order List Regulations',
      '輸出貿易管理令別表第1の1から15の項 リスト規制',
      'Paragraphs 1-15 of Appended Table 1 of the Export Trade Control Order List Regulations',
      '輸出貿易管理令別表第1の1から15の項 リスト規制',
      LawId.LAW_FOREIGN_EXCHANGE_AND_FOREIGN_TRADE_ACT.value
    );
  static readonly REGULATION1_LAW_FOREIGN_EXCHANGE_AND_FOREIGN_TRADE_ACT_0003 =
    new RegulationId(
      200050003,
      '輸出貿易管理令;Export Trade Control Order',
      '輸出貿易管理令',
      'Export Trade Control Order',
      '輸出貿易管理令',
      LawId.LAW_FOREIGN_EXCHANGE_AND_FOREIGN_TRADE_ACT.value
    );
  static readonly REGULATION1_LAW_ACT_ON_THE_PROTECTION_OF_THE_OZONE_LAYER_0000 =
    new RegulationId(
      200060000,
      '特定物質 ; Specified substance',
      '特定物質',
      'Specified substance',
      '特定物質',
      100060000
    );

  static readonly REGULATION1_LAW_ACT_ON_THE_PROTECTION_OF_THE_OZONE_LAYER_0001 =
    new RegulationId(
      200060001,
      '特定物質代替物質 ; Alternatives',
      '特定物質代替物質',
      'Alternatives',
      '特定物質代替物質',
      100060000
    );

  static readonly REGULATION1_LAW_AIR_POLLUTION_CONTROL_ACT_0000 =
    new RegulationId(
      200070000,
      'ばい煙（有害物質） ; Soot and Smoke (Hazardous Substances)',
      'ばい煙（有害物質）',
      'Soot and Smoke (Hazardous Substances)',
      'ばい煙（有害物質）',
      100070000
    );

  static readonly REGULATION1_LAW_AIR_POLLUTION_CONTROL_ACT_0001 =
    new RegulationId(
      200070001,
      'ばい煙 ; Soot and Smoke',
      'ばい煙',
      'Soot and Smoke',
      'ばい煙',
      100070000
    );

  static readonly REGULATION1_LAW_AIR_POLLUTION_CONTROL_ACT_0002 =
    new RegulationId(
      200070002,
      '揮発性有機化合物（VOC） ; Volatile Organic Compound (VOC)',
      '揮発性有機化合物（VOC）',
      'Volatile Organic Compound (VOC)',
      '揮発性有機化合物（VOC）',
      100070000
    );

  static readonly REGULATION1_LAW_AIR_POLLUTION_CONTROL_ACT_0003 =
    new RegulationId(
      200070003,
      'VOCの除外物質 ; VOC Excluded Substances',
      'VOCの除外物質',
      'VOC Excluded Substances',
      'VOCの除外物質',
      100070000
    );

  static readonly REGULATION1_LAW_AIR_POLLUTION_CONTROL_ACT_0004 =
    new RegulationId(
      200070004,
      '特定粉じん ; Specified Particulates',
      '特定粉じん',
      'Specified Particulates',
      '特定粉じん',
      100070000
    );

  static readonly REGULATION1_LAW_AIR_POLLUTION_CONTROL_ACT_0005 =
    new RegulationId(
      200070005,
      '水銀等 ; Mercury and Its Compounds',
      '水銀等',
      'Mercury and Its Compounds',
      '水銀等',
      100070000
    );

  static readonly REGULATION1_LAW_AIR_POLLUTION_CONTROL_ACT_0006 =
    new RegulationId(
      200070006,
      '有害大気汚染物質（優先取組） ; Hazardous Air Pollutant (Priority Chemicals)',
      '有害大気汚染物質（優先取組）',
      'Hazardous Air Pollutant (Priority Chemicals)',
      '有害大気汚染物質（優先取組）',
      100070000
    );

  static readonly REGULATION1_LAW_AIR_POLLUTION_CONTROL_ACT_0007 =
    new RegulationId(
      200070007,
      '有害大気汚染物質（指定物質） ; Hazardous Air Pollutant (Designated Substance)',
      '有害大気汚染物質（指定物質）',
      'Hazardous Air Pollutant (Designated Substance)',
      '有害大気汚染物質（指定物質）',
      100070000
    );

  static readonly REGULATION1_LAW_AIR_POLLUTION_CONTROL_ACT_0008 =
    new RegulationId(
      200070008,
      '有害大気汚染物質 ; Hazardous Air Pollutant',
      '有害大気汚染物質',
      'Hazardous Air Pollutant',
      '有害大気汚染物質',
      100070000
    );

  static readonly REGULATION1_LAW_AIR_POLLUTION_CONTROL_ACT_0009 =
    new RegulationId(
      200070009,
      '自動車排出ガス ; Automobile Exhaust',
      '自動車排出ガス',
      'Automobile Exhaust',
      '自動車排出ガス',
      100070000
    );

  static readonly REGULATION1_LAW_AIR_POLLUTION_CONTROL_ACT_0010 =
    new RegulationId(
      200070010,
      '特定物質 ; Specified Substance',
      '特定物質',
      'Specified Substance',
      '特定物質',
      100070000
    );

  static readonly REGULATION1_LAW_WATER_POLLUTION_PREVENTION_ACT_0000 =
    new RegulationId(
      200080000,
      '有害物質 ; Harmful Substances',
      '有害物質',
      'Harmful Substances',
      '有害物質',
      100080000
    );

  static readonly REGULATION1_LAW_WATER_POLLUTION_PREVENTION_ACT_0001 =
    new RegulationId(
      200080001,
      '指定物質 ; Designated Substances',
      '指定物質',
      'Designated Substances',
      '指定物質',
      100080000
    );

  static readonly REGULATION1_LAW_WATER_POLLUTION_PREVENTION_ACT_0002 =
    new RegulationId(
      200080002,
      '排出規制 ; Effluent Regulation',
      '排出規制',
      'Effluent Regulation',
      '排出規制',
      100080000
    );

  static readonly REGULATION1_LAW_WATER_POLLUTION_PREVENTION_ACT_0003 =
    new RegulationId(
      200080003,
      '油分排出規制 ; Oil Effluent Regulation',
      '油分排出規制',
      'Oil Effluent Regulation',
      '油分排出規制',
      100080000
    );

  static readonly REGULATION1_LAW_WATER_POLLUTION_PREVENTION_ACT_0004 =
    new RegulationId(
      200080004,
      '個別輸送（個品運送） ; Individual Transportation',
      '個別輸送（個品運送）',
      'Individual Transportation',
      '個別輸送（個品運送）',
      100080000
    );

  static readonly REGULATION1_LAW_WATER_POLLUTION_PREVENTION_ACT_0005 =
    new RegulationId(
      200080005,
      '個品運送P ; Individual Transportation P',
      '個品運送P',
      'Individual Transportation P',
      '個品運送P',
      100080000
    );

  static readonly REGULATION1_LAW_SOIL_CONTAMINATION_COUNTERMEASURES_ACT_0000 =
    new RegulationId(
      200090000,
      '特定有害物質 ; Designated Hazardous Substances',
      '特定有害物質',
      'Designated Hazardous Substances',
      '特定有害物質',
      100090000
    );

  static readonly REGULATION1_LAW_SOIL_CONTAMINATION_COUNTERMEASURES_ACT_0001 =
    new RegulationId(
      200090001,
      '第一種特定有害物質 ; Class 1 Designated Hazardous Substances',
      '第一種特定有害物質',
      'Class 1 Designated Hazardous Substances',
      '第一種特定有害物質',
      100090000
    );

  static readonly REGULATION1_LAW_SOIL_CONTAMINATION_COUNTERMEASURES_ACT_0002 =
    new RegulationId(
      200090002,
      '第二種特定有害物質 ; Class 2 Designated Hazardous Substances',
      '第二種特定有害物質',
      'Class 2 Designated Hazardous Substances',
      '第二種特定有害物質',
      100090000
    );

  static readonly REGULATION1_LAW_SOIL_CONTAMINATION_COUNTERMEASURES_ACT_0003 =
    new RegulationId(
      200090003,
      '第三種特定有害物質 ; Class 3 Designated Hazardous Substances',
      '第三種特定有害物質',
      'Class 3 Designated Hazardous Substances',
      '第三種特定有害物質',
      100090000
    );

  static readonly REGULATION1_LAW_FOOD_SANITATION_ACT_0000 = new RegulationId(
    200100000,
    '基ポリマー（プラスチック） ; Base polymers (Plastics)',
    '基ポリマー（プラスチック）',
    'Base polymers (Plastics)',
    '基ポリマー（プラスチック）',
    100100000
  );

  static readonly REGULATION1_LAW_FOOD_SANITATION_ACT_0001 = new RegulationId(
    200100001,
    '基ポリマー（コーティング等） ;  Base polymers (Coatings)',
    '基ポリマー（コーティング等）',
    'Base polymers (Coatings)',
    '基ポリマー（コーティング等）',
    100100000
  );

  static readonly REGULATION1_LAW_FOOD_SANITATION_ACT_0002 = new RegulationId(
    200100002,
    '基ポリマー（微量モノマー） ; Base polymers (Trace amount monomer)',
    '基ポリマー（微量モノマー）',
    'Base polymers (Trace amount monomer)',
    '基ポリマー（微量モノマー）',
    100100000
  );

  static readonly REGULATION1_LAW_FOOD_SANITATION_ACT_0003 = new RegulationId(
    200100003,
    '基ポリマー ; Base polymers',
    '基ポリマー',
    'Base polymers',
    '基ポリマー',
    100100000
  );

  static readonly REGULATION1_LAW_FOOD_SANITATION_ACT_0004 = new RegulationId(
    200100004,
    '添加剤 ; Additives',
    '添加剤',
    'Additives',
    '添加剤',
    100100000
  );

  static readonly REGULATION1_LAW_FOOD_SANITATION_ACT_0005 = new RegulationId(
    200100005,
    '指定添加物 ; Additives',
    '指定添加物',
    'Additives',
    '指定添加物',
    100100000
  );

  static readonly REGULATION1_LAW_FOOD_SANITATION_ACT_0006 = new RegulationId(
    200100006,
    '人の健康を損なう恐れのない添加物 ; Additives',
    '人の健康を損なう恐れのない添加物',
    'Additives',
    '人の健康を損なう恐れのない添加物',
    100100000
  );

  static readonly REGULATION1_LAW_FOOD_SANITATION_ACT_0007 = new RegulationId(
    200100007,
    '食品包装として使用する場合',
    '食品包装として使用する場合',
    '食品包装として使用する場合',
    '食品包装として使用する場合',
    100100000
  );

  static readonly REGULATION1_LAW_ACT_ON_PREVENTION_OF_MARINE_POLLUTION_0000 =
    new RegulationId(
      200110000,
      '有害液体物質（Z類物質） ; Harmful Liquid Substances (Z)',
      '有害液体物質（Z類物質）',
      'Harmful Liquid Substances (Z)',
      '有害液体物質（Z類物質）',
      100110000
    );

  static readonly REGULATION1_LAW_SHIP_SAFETY_LAW_0000 = new RegulationId(
    200120000,
    '火薬類 ; Explosives',
    '火薬類',
    'Explosives',
    '火薬類',
    100120000
  );

  static readonly REGULATION1_LAW_SHIP_SAFETY_LAW_0001 = new RegulationId(
    200120001,
    '高圧ガス ; High-pressure gases',
    '高圧ガス',
    'High-pressure gases',
    '高圧ガス',
    100120000
  );

  static readonly REGULATION1_LAW_SHIP_SAFETY_LAW_0002 = new RegulationId(
    200120002,
    '引火性液体類 ; Inflammable liquids',
    '引火性液体類',
    'Inflammable liquids',
    '引火性液体類',
    100120000
  );

  static readonly REGULATION1_LAW_SHIP_SAFETY_LAW_0003 = new RegulationId(
    200120003,
    '可燃性物質類 ; Flammable substances',
    '可燃性物質類',
    'Flammable substances',
    '可燃性物質類',
    100120000
  );

  static readonly REGULATION1_LAW_SHIP_SAFETY_LAW_0004 = new RegulationId(
    200120004,
    '酸化性物質類 ; Oxidizing Substances',
    '酸化性物質類',
    'Oxidizing Substances',
    '酸化性物質類',
    100120000
  );
  static readonly REGULATION1_LAW_SHIP_SAFETY_LAW_0005 = new RegulationId(
    200120005,
    '毒物類 ; Poisons',
    '毒物類',
    'Poisons',
    '毒物類',
    100120000
  );

  static readonly REGULATION1_LAW_SHIP_SAFETY_LAW_0006 = new RegulationId(
    200120006,
    '放射性物質等 ; Radioactive substances',
    '放射性物質等',
    'Radioactive substances',
    '放射性物質等',
    100120000
  );

  static readonly REGULATION1_LAW_SHIP_SAFETY_LAW_0007 = new RegulationId(
    200120007,
    '腐食性物質 ; Corrosive substances',
    '腐食性物質',
    'Corrosive substances',
    '腐食性物質',
    100120000
  );

  static readonly REGULATION1_LAW_SHIP_SAFETY_LAW_0008 = new RegulationId(
    200120008,
    '有害性物質 ; Toxic substances',
    '有害性物質',
    'Toxic substances',
    '有害性物質',
    100120000
  );

  static readonly REGULATION1_LAW_SHIP_SAFETY_LAW_0009 = new RegulationId(
    200120009,
    '危険物船舶運送及び貯蔵規則（危規則）; Regulations for the Carriage and Storage of Dangerous Goods in Ship',
    '危険物船舶運送及び貯蔵規則（危規則）; Regulations for the Carriage and Storage of Dangerous Goods in Ship',
    'Regulations for the Carriage and Storage of Dangerous Goods in Ship',
    '危険物船舶運送及び貯蔵規則（危規則）',
    100120000
  );

  static readonly REGULATION1_LAW_CIVIL_AERONAUTICS_ACT_0000 = new RegulationId(
    200130000,
    '第１号　火薬類 ; Class. 1 Explosives',
    '第１号　火薬類',
    'Class. 1 Explosives',
    '第１号　火薬類',
    100130000
  );

  static readonly REGULATION1_LAW_CIVIL_AERONAUTICS_ACT_0001 = new RegulationId(
    200130001,
    '第２号　高圧ガス（引火性ガス、毒性ガス、その他のガス） ; Class. 2 High pressure gases (flammable gases, toxic gases, other gases)',
    '第２号　高圧ガス（引火性ガス、毒性ガス、その他のガス）',
    'Class. 2 High pressure gases (flammable gases, toxic gases, other gases)',
    '第２号　高圧ガス（引火性ガス、毒性ガス、その他のガス）',
    100130000
  );

  static readonly REGULATION1_LAW_CIVIL_AERONAUTICS_ACT_0002 = new RegulationId(
    200130002,
    '第３号　引火性液体 ; Class. 3 Inflammable liquids',
    '第３号　引火性液体',
    'Class. 3 Inflammable liquids',
    '第３号　引火性液体',
    100130000
  );

  static readonly REGULATION1_LAW_CIVIL_AERONAUTICS_ACT_0003 = new RegulationId(
    200130003,
    '第４号　可燃性物質類（可燃性物質、自然発火性物質、水反応可燃性物質） ; Class. 4 Combustible substances (combustible substances, spontaneous combustible substances, water-reactive combustible substances)',
    '第４号　可燃性物質類（可燃性物質、自然発火性物質、水反応可燃性物質）',
    'Class. 4 Combustible substances (combustible substances, spontaneous combustible substances, water-reactive combustible substances)',
    '第４号　可燃性物質類（可燃性物質、自然発火性物質、水反応可燃性物質）',
    100130000
  );

  static readonly REGULATION1_LAW_CIVIL_AERONAUTICS_ACT_0004 = new RegulationId(
    200130004,
    '第５号　酸化性物質類（酸化性物質、有機過酸化物） ; Class. 5 Oxidizing substances (oxidizing substances, organic peroxides)',
    '第５号　酸化性物質類（酸化性物質、有機過酸化物）',
    'Class. 5 Oxidizing substances (oxidizing substances, organic peroxides)',
    '第５号　酸化性物質類（酸化性物質、有機過酸化物）',
    100130000
  );

  static readonly REGULATION1_LAW_CIVIL_AERONAUTICS_ACT_0005 = new RegulationId(
    200130005,
    '第６号　毒物類（毒物、病毒を移しやすい物質） ; Class. 6 PoisoClassus substances (poisoClassus substances, substances that can easily transfer disease or poison)',
    '第６号　毒物類（毒物、病毒を移しやすい物質）',
    ' Class. 6 PoisoClassus substances (poisoClassus substances, substances that can easily transfer disease or poison)',
    '第６号　毒物類（毒物、病毒を移しやすい物質）',
    100130000
  );

  static readonly REGULATION1_LAW_CIVIL_AERONAUTICS_ACT_0006 = new RegulationId(
    200130006,
    '第７号　放射性物質等 ; Class. 7 Radioactive substances, etc.',
    '第７号　放射性物質等',
    'Class. 7 Radioactive substances, etc.',
    '第７号　放射性物質等',
    100130000
  );

  static readonly REGULATION1_LAW_CIVIL_AERONAUTICS_ACT_0007 = new RegulationId(
    200130007,
    '第８号　腐食性物質 ; Class. 8 Corrosive substances',
    '第８号　腐食性物質',
    'Class. 8 Corrosive substances',
    '第８号　腐食性物質',
    100130000
  );

  static readonly REGULATION1_LAW_CIVIL_AERONAUTICS_ACT_0008 = new RegulationId(
    200130008,
    '第９号　その他の有害物件 ; Class. 9 Other harmful substances',
    '第９号　その他の有害物件',
    'Class. 9 Other harmful substances',
    '第９号　その他の有害物件',
    100130000
  );

  static readonly REGULATION1_LAW_CIVIL_AERONAUTICS_ACT_0009 = new RegulationId(
    200130009,
    '第１０号　凶器 ; Class. 10 Weapons',
    '第１０号　凶器',
    'Class. 10 Weapons',
    '第１０号　凶器',
    100130000
  );

  static readonly REGULATION1_LAW_PORT_REGULATIONS_ACT_0000 = new RegulationId(
    200140000,
    'その他の危険物・腐食性物質 ; Other Harmful or Corrosive Substances',
    'その他の危険物・腐食性物質',
    'Other Harmful or Corrosive Substances',
    'その他の危険物・腐食性物質',
    100140000
  );

  static readonly REGULATION1_LAW_WASTE_MANAGEMENT_AND_PUBLIC_CLEANSING_ACT_0000 =
    new RegulationId(
      200150000,
      '特別管理産業廃棄物 ; Specially Controlled Industrial Waste',
      '特別管理産業廃棄物',
      'Specially Controlled Industrial Waste',
      '特別管理産業廃棄物',
      100150000
    );

  static readonly REGULATION1_LAW_WASTE_MANAGEMENT_AND_PUBLIC_CLEANSING_ACT_0001 =
    new RegulationId(
      200150001,
      '産業廃棄物 ; Industrial waste',
      '産業廃棄物',
      'Industrial waste',
      '産業廃棄物',
      100150000
    );

  static readonly REGULATION1_LAW_ACT_ON_THE_CONTROL_OF_HOUSEHOLD_PRODUCTS_CONTAINING_HARMFUL_SUBSTANCES_0000 =
    new RegulationId(
      200170000,
      '有害物質 ; Hazardous Substances',
      '有害物質',
      'Hazardous Substances',
      '有害物質',
      100170000
    );

  static readonly REGULATION1_LAW_ACT_ON_THE_CONTROL_OF_HOUSEHOLD_PRODUCTS_CONTAINING_HARMFUL_SUBSTANCES_0001 =
    new RegulationId(
      200170001,
      '特定芳香族アミン ; Specific Aromatic Amine',
      '特定芳香族アミン',
      'Specific Aromatic Amine',
      '特定芳香族アミン',
      100170000
    );

  static readonly REGULATION1_LAW_WATER_SUPPLY_ACT_0000 = new RegulationId(
    200230000,
    '有害物質 ; Harmful Substances',
    '有害物質',
    'Harmful Substances',
    '有害物質',
    100230000
  );

  static readonly REGULATION1_LAW_SEWERAGE_ACT_0000 = new RegulationId(
    200240000,
    '鉱油類排出規制 ; Mineral oil emission regulations',
    '鉱油類排出規制',
    'Mineral oil emission regulations',
    '鉱油類排出規制',
    100240000
  );

  static readonly REGULATION1_LAW_LABOR_STANDARDS_ACT_0000 = new RegulationId(
    200250000,
    'エポキシ樹脂の硬化剤による健康障害防止',
    'エポキシ樹脂の硬化剤による健康障害防止',
    'エポキシ樹脂の硬化剤による健康障害防止',
    'エポキシ樹脂の硬化剤による健康障害防止',
    100250000
  );
  static readonly REGULATION1_LAW_PMD_ACT_0000 = new RegulationId(
    200300000,
    '日本薬局方;Japanese Pharmaceutical Codex',
    '日本薬局方',
    'Japanese Pharmaceutical Codex',
    '日本薬局方',
    LawId.LAW_PMD_ACT.value
  );
  static readonly REGULATION2_LAW_ISHL_0000 = new RegulationId(
    300020000,
    '発火性 ; Ignitable',
    '発火性',
    'Ignitable',
    '発火性',
    100020000
  );
  static readonly REGULATION2_LAW_ISHL_0001 = new RegulationId(
    300020001,
    '酸化性 ; Oxidizing',
    '酸化性',
    'Oxidizing',
    '酸化性',
    100020000
  );
  static readonly REGULATION2_LAW_ISHL_0002 = new RegulationId(
    300020002,
    '可燃性ガス ; Flammable Gas',
    '可燃性ガス',
    'Flammable Gas',
    '可燃性ガス',
    100020000
  );
  static readonly REGULATION2_LAW_ISHL_0003 = new RegulationId(
    300020003,
    '引火性 ; Dangerous Substances_Flammable',
    '引火性',
    'Dangerous Substances_Flammable',
    '引火性',
    100020000
  );
  static readonly REGULATION2_LAW_ISHL_0004 = new RegulationId(
    300020004,
    '第一類特定化学物質 ; Class I',
    '第一類特定化学物質',
    'Class I',
    '第一類特定化学物質',
    100020000
  );
  static readonly REGULATION2_LAW_ISHL_0005 = new RegulationId(
    300020005,
    '第二類特定化学物質 ; Class II',
    '第二類特定化学物質',
    'Class II',
    '第二類特定化学物質',
    100020000
  );
  static readonly REGULATION2_LAW_ISHL_0006 = new RegulationId(
    300020006,
    '第三類特定化学物質 ; Class III',
    '第三類特定化学物質',
    'Class III',
    '第三類特定化学物質',
    100020000
  );
  static readonly REGULATION2_LAW_ISHL_0007 = new RegulationId(
    300020007,
    '第一種有機溶剤 ; Class I',
    '第一種有機溶剤',
    'Class I',
    '第一種有機溶剤',
    100020000
  );
  static readonly REGULATION2_LAW_ISHL_0008 = new RegulationId(
    300020008,
    '第二種有機溶剤 ; Class II',
    '第二種有機溶剤',
    'Class II',
    '第二種有機溶剤',
    100020000
  );
  static readonly REGULATION2_LAW_ISHL_0009 = new RegulationId(
    300020009,
    '第三種有機溶剤 ; Class III',
    '第三種有機溶剤',
    'Class III',
    '第三種有機溶剤',
    100020000
  );
  static readonly REGULATION2_LAW_ISHL_0010 = new RegulationId(
    300020010,
    '既存化学物質 ; Strong Mutagenic Chemical Substances_Existing Chemical Substances',
    '既存化学物質',
    'Strong Mutagenic Chemical Substances_Existing Chemical Substances',
    '既存化学物質',
    100020000
  );
  static readonly REGULATION2_LAW_ISHL_0011 = new RegulationId(
    300020011,
    '新規届出化学物質 ; Newly Announced Chemical Substances',
    '新規届出化学物質',
    'Newly Announced Chemical Substances',
    '新規届出化学物質',
    100020000
  );
  static readonly REGULATION2_LAW_ISHL_0012 = new RegulationId(
    300020012,
    '爆発性 ; Explosive',
    '爆発性',
    'Explosive',
    '爆発性',
    100020000
  );
  static readonly REGULATION2_LAW_ISHL_0013 = new RegulationId(
    300020013,
    '皮膚刺激性有害物質',
    '皮膚刺激性有害物質',
    '皮膚刺激性有害物質',
    '皮膚刺激性有害物質',
    LawId.LAW_ISHL.value
  );
  static readonly REGULATION2_LAW_ISHL_0014 = new RegulationId(
    300020014,
    '皮膚吸収性有害物質 ; Skin Absorbable Hazardous Substances',
    '皮膚吸収性有害物質',
    'Skin Absorbable Hazardous Substances',
    '皮膚吸収性有害物質',
    LawId.LAW_ISHL.value
  );
  static readonly REGULATION2_LAW_FIRE_SERVICE_ACT_0001 = new RegulationId(
    300040001,
    '自己反応性物質 ; Self-Reactive Substances',
    '自己反応性物質',
    'Self-Reactive Substances',
    '自己反応性物質',
    100040000
  );
  static readonly REGULATION2_LAW_FIRE_SERVICE_ACT_0002 = new RegulationId(
    300040002,
    '酸化性固体 ; Oxidizing Solids',
    '酸化性固体',
    'Oxidizing Solids',
    '酸化性固体',
    100040000
  );
  static readonly REGULATION2_LAW_FIRE_SERVICE_ACT_0003 = new RegulationId(
    300040003,
    '酸化性液体 ; Oxidizing Liquids',
    '酸化性液体',
    'Oxidizing Liquids',
    '酸化性液体',
    100040000
  );
  static readonly REGULATION2_LAW_FIRE_SERVICE_ACT_0004 = new RegulationId(
    300040004,
    '可燃性固体 ; Combustible Solids',
    '可燃性固体',
    'Combustible Solids',
    '可燃性固体',
    100040000
  );
  static readonly REGULATION2_LAW_FIRE_SERVICE_ACT_0005 = new RegulationId(
    300040005,
    '引火性液体 ; Inflammable Liquids',
    '引火性液体',
    'Inflammable Liquids',
    '引火性液体',
    100040000
  );
  static readonly REGULATION2_LAW_FIRE_SERVICE_ACT_0006 = new RegulationId(
    300040006,
    '可燃性液体類 ; Flammable liquids',
    '可燃性液体類',
    'Flammable liquids',
    '可燃性液体類',
    LawId.LAW_FIRE_SERVICE_ACT.value
  );
  static readonly REGULATION2_LAW_FIRE_SERVICE_ACT_0007 = new RegulationId(
    300040007,
    '可燃性固体類 ; Flammable solids',
    '可燃性固体類',
    'Flammable solids',
    '可燃性固体類',
    LawId.LAW_FIRE_SERVICE_ACT.value
  );
  static readonly REGULATION2_LAW_FIRE_SERVICE_ACT_0008 = new RegulationId(
    300040008,
    '合成樹脂類 ; Synthetic resins',
    '合成樹脂類',
    'Synthetic resins',
    '合成樹脂類',
    LawId.LAW_FIRE_SERVICE_ACT.value
  );
  static readonly REGULATION2_LAW_FIRE_SERVICE_ACT_0000 = new RegulationId(
    300040000,
    '自然発火性及び禁水性物質 ; Pyrophoric And Water-Prohibiting Substances',
    '自然発火性及び禁水性物質',
    'Pyrophoric And Water-Prohibiting Substances',
    '自然発火性及び禁水性物質',
    100040000
  );
  static readonly REGULATION2_LAW_CIVIL_AERONAUTICS_ACT_0000 = new RegulationId(
    300130000,
    '施行規則第194条危険物告示別表第1',
    '施行規則第194条危険物告示別表第1',
    '施行規則第194条危険物告示別表第1',
    '施行規則第194条危険物告示別表第1',
    100130000
  );
  static readonly REGULATION3_LAW_FIRE_SERVICE_ACT_0000 = new RegulationId(
    400040000,
    '第1種 ; Class1',
    '第1種',
    'Class1',
    '第1種',
    100040000
  );
  static readonly REGULATION3_LAW_FIRE_SERVICE_ACT_0001 = new RegulationId(
    400040001,
    '第2種 ; Class2',
    '第2種',
    'Class2',
    '第2種',
    100040000
  );
  static readonly REGULATION3_LAW_FIRE_SERVICE_ACT_0002 = new RegulationId(
    400040002,
    '第3種 ; Class3',
    '第3種',
    'Class3',
    '第3種',
    100040000
  );
  static readonly REGULATION3_LAW_FIRE_SERVICE_ACT_0003 = new RegulationId(
    400040003,
    '特殊引火物 ; Special Inflammable Materials',
    '特殊引火物',
    'Special Inflammable Materials',
    '特殊引火物',
    100040000
  );
  static readonly REGULATION3_LAW_FIRE_SERVICE_ACT_0004 = new RegulationId(
    400040004,
    'アルコール類 ; Alcohols',
    'アルコール類',
    'Alcohols',
    'アルコール類',
    100040000
  );
  static readonly REGULATION3_LAW_FIRE_SERVICE_ACT_0005 = new RegulationId(
    400040005,
    '第1石油類 ; Class I Petroleums',
    '第1石油類',
    'Class I Petroleums',
    '第1石油類',
    100040000
  );
  static readonly REGULATION3_LAW_FIRE_SERVICE_ACT_0006 = new RegulationId(
    400040006,
    '第2石油類 ; Class II Petroleums',
    '第2石油類',
    'Class II Petroleums',
    '第2石油類',
    100040000
  );
  static readonly REGULATION3_LAW_FIRE_SERVICE_ACT_0007 = new RegulationId(
    400040007,
    '第3石油類 ; Class III Petroleums',
    '第3石油類',
    'Class III Petroleums',
    '第3石油類',
    100040000
  );
  static readonly REGULATION3_LAW_FIRE_SERVICE_ACT_0008 = new RegulationId(
    400040008,
    '第4石油類 ; Class IV Petroleums',
    '第4石油類',
    'Class IV Petroleums',
    '第4石油類',
    100040000
  );
  static readonly REGULATION3_LAW_FIRE_SERVICE_ACT_0009 = new RegulationId(
    400040009,
    '動植物油類 ; Oil Extracted From Plants And Animals',
    '動植物油類',
    'Oil Extracted From Plants And Animals',
    '動植物油類',
    100040000
  );
  static readonly REGULATION3_LAW_FIRE_SERVICE_ACT_0010 = new RegulationId(
    400040010,
    '引火性固体 ; Inflammable Solids',
    '引火性固体',
    'Inflammable Solids',
    '引火性固体',
    100040000
  );
  static readonly REGULATION4_LAW_FIRE_SERVICE_ACT_0000 = new RegulationId(
    500040000,
    '非水溶性 ; Water-Insoluble',
    '非水溶性',
    'Water-Insoluble',
    '非水溶性',
    100040000
  );
  static readonly REGULATION4_LAW_FIRE_SERVICE_ACT_0001 = new RegulationId(
    500040001,
    '水溶性 ; Water-Soluble',
    '水溶性',
    'Water-Soluble',
    '水溶性',
    100040000
  );

  static fromId(value: number): RegulationId {
    const res = RegulationId._values.find((x) => x.value === value);
    if (res) {
      return res;
    } else {
      return RegulationId.DEFAULT;
    }
  }

  static values(): RegulationId[] {
    return RegulationId._values;
  }
}
