import { User } from '@/interfaces/vuex/user';
import { RoleId } from '@/lib/enum';

export const NewUser: User = {
  uuid: '',
  first_name: '',
  last_name: '',
  middle_name: '',
  email: '',
  company_id: '',
  role_id: RoleId.USER_VIEWER.value,
  role: {
    id: RoleId.USER_VIEWER.value,
    name: '',
    permissions: [],
  },
  is_active: false,
  phone: '',
  is_subscribe: true,
  departments: [],
  position: '',
  created_at: '',
  tfa_phone_number: null,
};
