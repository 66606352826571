import { FormItem } from '@/interfaces/lib/model';
import {
  CompositionId,
  LawId,
  PlanId,
  RegulationId,
  RoleId,
  Section2GHSCategoryId,
  Section2GHSHazardId,
  Section2GHSItemId,
  StatusId,
  TemperatureUnitId,
} from '@/lib/enum';
import { i18n } from '@/plugins/vue-i18n';
import { defineMessages } from '@/lib/i18n';
import sharedMessages from '@/lib/shared-messages';
import { readUserLocale } from '@/store/main/getters';
import { store } from '@/store/index';

const $t = i18n.tc.bind(i18n);
const $n = i18n.n.bind(i18n);
const locale = readUserLocale(store);

const localeSuffix = locale === 'en-US' ? 'En' : '';

const m = defineMessages({
  max: '拡大',
  min: '縮小',
  warning: '警告 ; Warning',
  danger: '危険 ; Danger',
  notSelected: '物質選択なし',
  worker: '作業者 ; Worker',
  consumer: '消費者 ; Consumer',
  other: 'その他 ; Other',
  longTerm: 'Long-term',
  shortTerm: 'Short-term',
  dermal: '経皮 ; Dermal',
  inhalation: '吸入 ; Inhalation',
  oral: '経口 ; Oral',
  systemic: 'Systemic',
  local: 'Local',
  allowableConcentration: '許容濃度 ; Allowable Concentration',
  maxPermissibleConcentration:
    '最大許容濃度 ; Maximum Permissible Concentration ; Ceiling',
  class1: '第1種粉塵 ; Class1',
  class2: '第2種粉塵 ; Class2',
  class3: '第3種粉塵 ; Class3',
  respirableDust: '吸入性粉じん ; Respirable dust',
  total: '総粉じん ; Total',
  applicable: '該当する',
  notApplicable: '該当しない（非該当）',
  weightPercentageMassPercentage: 'wt%・質量%・重量%',
});

export const unitList = [
  { text: '%', value: '%' },
  { text: 'ppm', value: 'ppm' },
  { text: 'mg/m³', value: 'mg/m3' },
  { text: '', value: '' },
];

export const prices: Array<FormItem> = PlanId.values().map((x: PlanId) => {
  return {
    value: x.value,
    text:
      x.maxUpload !== null
        ? x.maxUpload === Infinity
          ? $t(sharedMessages.unlimited)
          : $n(x.maxUpload, 'decimal')
        : $t(sharedMessages.freePlan),
    disabled: x.value === PlanId.FREE.value ? true : false,
  };
});

export const getStatusList = (): Array<FormItem> => [
  {
    value: StatusId.UPLOADING.value,
    text: $t(StatusId.UPLOADING.messageId),
    disabled: true,
  },
  {
    value: StatusId.ASSORT_DONE.value,
    text: $t(StatusId.ASSORT_DONE.messageId),
    disabled: true,
  },
  {
    value: StatusId.FIRST_CHECK_PROCESSING.value,
    text: $t(StatusId.FIRST_CHECK_PROCESSING.messageId),
    disabled: true,
  },
  {
    value: StatusId.SECOND_CHECK_PROCESSING.value,
    text: $t(StatusId.SECOND_CHECK_PROCESSING.messageId),
    disabled: true,
  },
  {
    value: StatusId.SECOND_CHECK_DONE.value,
    text: $t(StatusId.SECOND_CHECK_DONE.messageId),
    disabled: true,
  },
  {
    value: StatusId.ERROR.value,
    text: $t(StatusId.ERROR.messageId),
    disabled: true,
  },
  {
    value: StatusId.ERROR_RECONFIRMATION.value,
    text: $t(StatusId.ERROR_RECONFIRMATION.messageId),
    disabled: true,
  },
];

type PdfDisplayOptionValue = 'default' | 'max' | 'min' | 'none';

interface PdfDisplayOption {
  value: PdfDisplayOptionValue;
  name: string;
}

export const getPdfDisplayOptions = (): PdfDisplayOption[] => {
  return [
    { value: 'none', name: $t(sharedMessages.none) },
    { value: 'default', name: $t(sharedMessages.default) },
    { value: 'max', name: $t(m.max) },
    { value: 'min', name: $t(m.min) },
  ];
};

export const getRoleList = (): Array<FormItem> => [
  {
    value: RoleId.USER_VIEWER.value,
    text: $t(RoleId.USER_VIEWER.messageId),
  },
  {
    value: RoleId.USER_DOWNLOADABLE_VIEWER.value,
    text: $t(RoleId.USER_DOWNLOADABLE_VIEWER.messageId),
  },
  {
    value: RoleId.USER_EDITOR.value,
    text: $t(RoleId.USER_EDITOR.messageId),
  },
  {
    value: RoleId.USER_ADMIN.value,
    text: $t(RoleId.USER_ADMIN.messageId),
  },
];

export const getSignalWordsList = (): Array<FormItem> => [
  { value: 'なし', text: $t(sharedMessages.none) },
  { value: '警告 ; Warning', text: $t(m.warning) },
  { value: '危険 ; Danger', text: $t(m.danger) },
];

export const textFormList: Array<FormItem> = [{ value: '-', text: '-' }];

export const getCompositionList = (): Array<FormItem> => [
  { value: CompositionId.DEFAULT.value, text: CompositionId.DEFAULT.text },
  {
    value: CompositionId.SUBSTANCE.value,
    text: CompositionId.SUBSTANCE.text,
  },
  {
    value: CompositionId.MIXTURES.value,
    text: CompositionId.MIXTURES.text,
  },
  {
    value: CompositionId.UVCB.value,
    text: CompositionId.UVCB.text,
  },
];

export const getSkinProtectionList = (): Array<FormItem> => [
  { value: '-', text: '-' },
  { value: 'あり', text: $t(sharedMessages.present) },
];

export const getMaterialNameList = (): Array<FormItem> => [
  { value: $t(m.notSelected), text: $t(m.notSelected) },
];

export const getSection8ACGIHCateoryList = (): Array<FormItem> => [
  { value: 0, text: '-' },
  { value: 100, text: 'TLV-TWA' },
  { value: 101, text: 'TLV-STEL' },
  { value: 102, text: 'C(Ceiling)' },
];

export const getSection8DNELDMELCateoryUserList = (): Array<FormItem> => [
  { value: 0, text: '-' },
  { value: 200, text: $t(m.worker) },
  { value: 201, text: $t(m.consumer) },
  { value: 202, text: $t(m.other) },
];

export const getSection8DNELDMELCateoryTermList = (): Array<FormItem> => [
  { value: 0, text: '-' },
  { value: 300, text: $t(m.longTerm) },
  { value: 301, text: $t(m.shortTerm) },
];

export const getSection8DNELDMELCateoryDustRouteList = (): Array<FormItem> => [
  { value: 0, text: '-' },
  { value: 400, text: $t(m.dermal) },
  { value: 401, text: $t(m.inhalation) },
  { value: 402, text: $t(m.oral) },
  { value: 403, text: $t(m.other) },
];

export const getSection8DNELDMELCateoryMeasurementList =
  (): Array<FormItem> => [
    { value: 0, text: '-' },
    { value: 500, text: $t(m.systemic) },
    { value: 501, text: $t(m.local) },
  ];

export const getSection8JSOHCategoryConcentrationList = (): Array<FormItem> => [
  { value: 0, text: '-' },
  {
    value: 600,
    text: $t(m.allowableConcentration),
  },
  {
    value: 601,
    text: $t(m.maxPermissibleConcentration),
  },
];

export const getSection8JSOHCategoryDustList = (): Array<FormItem> => [
  { value: 0, text: '-' },
  {
    value: 701,
    text: $t(m.class1),
  },
  {
    value: 702,
    text: $t(m.class2),
  },
  {
    value: 703,
    text: $t(m.class3),
  },
];

export const getSection8JSOHCategoryDustRespirableList =
  (): Array<FormItem> => [
    { value: 0, text: '-' },
    {
      value: 800,
      text: $t(m.respirableDust),
    },
    {
      value: 801,
      text: $t(m.total),
    },
    {
      value: 802,
      text: $t(m.other),
    },
  ];

export const getTemperatureUnitList = (): Array<{
  value: number;
  text: string;
}> => [
  ...TemperatureUnitId.values()
    .filter((item) => item.value != 0)
    .map((item) => ({
      text: item[`text${localeSuffix}`],
      value: item.value,
    })),
];

export const getSection9InequalityList = (): Array<FormItem> => [
  { value: 1, text: $t(sharedMessages.equal) },
  { value: 2, text: $t(sharedMessages.greaterThanOrEqual) },
  { value: 3, text: $t(sharedMessages.greaterThan) },
  { value: 4, text: $t(sharedMessages.lessThanOrEqual) },
  { value: 5, text: $t(sharedMessages.lessThan) },
  { value: 6, text: $t(sharedMessages.greaterThanOrEqualLessOrEqual) },
  { value: 7, text: $t(sharedMessages.greaterThanOrEqualLess) },
  { value: 8, text: $t(sharedMessages.greaterThanLessOrEqual) },
  { value: 9, text: $t(sharedMessages.greaterThanLess) },
];

export const getSection15TypeList = (): Array<FormItem> => [
  { value: true, text: $t(m.applicable) },
  { value: false, text: $t(m.notApplicable) },
];

export const concentrationUnitList: Array<FormItem> = [
  { value: '%', text: '%' },
  { value: 'wt%', text: $t(m.weightPercentageMassPercentage) },
];

export const getConcentrationInequalityList = (): Array<FormItem> => [
  { value: 1, text: $t(sharedMessages.equal) },
  { value: 2, text: $t(sharedMessages.greaterThanOrEqual) },
  { value: 3, text: $t(sharedMessages.greaterThan) },
  { value: 4, text: $t(sharedMessages.lessThanOrEqual) },
  { value: 5, text: $t(sharedMessages.lessThan) },
  { value: 6, text: $t(sharedMessages.greaterThanOrEqualLessOrEqual) },
  { value: 7, text: $t(sharedMessages.greaterThanOrEqualLess) },
  { value: 8, text: $t(sharedMessages.greaterThanLessOrEqual) },
  { value: 9, text: $t(sharedMessages.greaterThanLess) },
];

export const getSection2HazardList = (): Array<{
  value: number;
  text: string;
  itemId: number;
}> => [
  ...Section2GHSHazardId.values().map((item) => ({
    text: item[`text${localeSuffix}`],
    value: item.value,
    itemId: item.itemId,
  })),
];

export const getSection2GHSCategoryList = (): Array<{
  value: number;
  text: string;
  itemId: number;
}> => [
  ...Section2GHSCategoryId.values().map((item) => ({
    text: item[`text${localeSuffix}`],
    value: item.value,
    itemId: item.itemId,
  })),
];

export const getSection2GHSItemList = (): Array<{
  value: number;
  text: string;
}> => [
  ...Section2GHSItemId.values().map((item) => ({
    text: item[`text${localeSuffix}`],
    value: item.value,
  })),
];

export const getLawList = () =>
  LawId.values().map((x: LawId) => {
    return {
      value: x.value,
      text: x[`text${localeSuffix}`],
    };
  });

export const getRegulation1List = () =>
  RegulationId.values()
    .filter((v) => ((v.value / 100000000) | 0) === 2)
    .map((x: RegulationId) => {
      return {
        value: x.value,
        text: x[`text${localeSuffix}`],
        lawId: x.lawId,
      };
    });

export const getRegulation2List = () =>
  RegulationId.values()
    .filter((v) => ((v.value / 100000000) | 0) === 3)
    .map((x: RegulationId) => {
      return {
        value: x.value,
        text: x[`text${localeSuffix}`],
        lawId: x.lawId,
      };
    });

export const getRegulation3List = () =>
  RegulationId.values()
    .filter((v) => ((v.value / 100000000) | 0) === 4)
    .map((x: RegulationId) => {
      return {
        value: x.value,
        text: x[`text${localeSuffix}`],
        lawId: x.lawId,
      };
    });

export const getRegulation4List = () =>
  RegulationId.values()
    .filter((v) => ((v.value / 100000000) | 0) === 5)
    .map((x: RegulationId) => {
      return {
        value: x.value,
        text: x[`text${localeSuffix}`],
        lawId: x.lawId,
      };
    });

export const convertAnyListToSelectList = (
  list: Record<string, string>[],
  valueProp = 'value',
  titleProp = 'title'
): { value: string; title: string }[] =>
  list.map((item) => ({ value: item[valueProp], title: item[titleProp] }));
