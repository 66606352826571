import { CreatedUser } from '@/interfaces/api/general/user';
import { Department } from '@/interfaces/model/general/department';

export const getJoinedDepartmentName = (departments?: Department[]): string => {
  if (!departments) return '';
  return departments.map((d) => d.name).join(', ');
};

export const getFullName = (user?: CreatedUser, empty = '') => {
  if (!user) {
    return empty;
  }
  return [user.last_name, user.middle_name, user.first_name].join(' ');
};

export const countString = (value: string) => {
  // NOTES: use [...value].length instead of value.length
  // e.g.
  // TypeScript
  //   "🏴󠁧󠁢󠁥󠁮󠁧󠁿".length is 14;
  //   new Intl.Segmenter('ja', { type: 'grapheme' }).segment(🏴󠁧󠁢󠁥󠁮󠁧󠁿).length is 1;
  //   [...🏴󠁧󠁢󠁥󠁮󠁧󠁿].length is 7;
  // Python
  //   len(🏴󠁧󠁢󠁥󠁮󠁧󠁿) is 7;
  // PostgreSQL
  //   length(🏴󠁧󠁢󠁥󠁮󠁧󠁿) is 7;
  return [...value].length;
};
