import { render, staticRenderFns } from "./SdsSection9BoilingPointForm.vue?vue&type=template&id=73e6d018&scoped=true"
import script from "./SdsSection9BoilingPointForm.vue?vue&type=script&setup=true&lang=ts"
export * from "./SdsSection9BoilingPointForm.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./SdsSection9BoilingPointForm.vue?vue&type=style&index=0&id=73e6d018&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73e6d018",
  null
  
)

export default component.exports