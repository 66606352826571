import { DateTime } from 'luxon';
import { readUserLocale } from '@/store/main/getters';
import { store } from '@/store/index';

export const fromISO = (datetime: string) => {
  // If no zone set, default zone is system's local zone.
  // https://github.com/moment/luxon/blob/master/docs/zones.md#system-zone-by-default
  return DateTime.fromISO(datetime, { zone: 'UTC' });
};

export const now = () => {
  return DateTime.now();
};

export const latest = (...dateTimes: Parameters<typeof DateTime.max>) => {
  return DateTime.max(...dateTimes);
};

const formatAndLocalizeTime = (
  formattedParts: Intl.DateTimeFormatPart[],
  locale: string
) => {
  const timeKanji = ['時', '分', '秒'];
  const hasTime = formattedParts.some(
    ({ type }) => type === 'hour' || type === 'minute' || type === 'second'
  );

  return (
    formattedParts
      .map(({ type, value }) => {
        if (
          type === 'year' ||
          type === 'month' ||
          type === 'day' ||
          type === 'hour' ||
          type === 'minute' ||
          type === 'second'
        ) {
          value = value.padStart(2, '0');
        }
        if (locale === 'ja-JP' && type === 'literal' && value === ':') {
          return timeKanji.shift() || '';
        }
        return value;
      })
      .join('') + (hasTime && locale === 'ja-JP' ? timeKanji.shift() : '')
  );
};

export const formatToDate = (
  dateTime: string | null,
  { zone = 'Asia/Tokyo' }: { zone?: string } = {}
): string => {
  if (!dateTime) {
    return '';
  }
  const locale = readUserLocale(store);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: zone,
  };
  try {
    const date = fromISO(dateTime).setZone(zone);
    // Format using formatToParts to guarantee consistency
    const formatter = new Intl.DateTimeFormat(locale, options);
    const formattedParts = formatter.formatToParts(date.toJSDate());

    return formatAndLocalizeTime(formattedParts, locale);
  } catch (error) {
    return '';
  }
};

export const formatToDateTime = (
  dateTime: string,
  { zone = 'Asia/Tokyo' }: { zone?: string } = {}
): string => {
  const locale = readUserLocale(store);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZone: zone,
  };
  try {
    const date = fromISO(dateTime).setZone(zone);
    // Format using formatToParts to guarantee consistency
    const formatter = new Intl.DateTimeFormat(locale, options);
    const formattedParts = formatter.formatToParts(date.toJSDate());

    return formatAndLocalizeTime(formattedParts, locale);
  } catch (error) {
    return '';
  }
};
